import React, { useState } from 'react';

import '../../App.css';
import '../css/Chat.css';

function ChatNotice({color, sections}) {
    return (
        <>
            <div className='chat-notice-outer-container' style={{'--color': color}}>
                <div className='chat-notice-header'>
                    <img src='https://support.romestaff.com/images/staff-logo.png'/>
                    <span style={{marginRight: '0px'}}>Roman Systems</span>
                    <span style={{color: '#f5a442'}}> • Bot</span>
                </div>

                <div className='chat-notice-content-container'>
                    {sections.map((section, _) => {
                        return (
                            <>
                                <div className='chat-notice-section'>
                                    <div className='chat-notice-section-header'>
                                        <h3><i className={section.logo}/> {section.title}</h3>
                                    </div>

                                    <div className='chat-notice-section-content'>
                                        {section.fields.map((field, _) => {
                                            return (
                                                <div className='chat-notice-section-content-field'>
                                                    <div className='chat-notice-section-content-field-header'>
                                                        {field.logo && <img src={field.logo}/>}
                                                        {field.title && <h3>{field.title}</h3>}
                                                    </div>
                                                    <span>{field.content}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
};

export default ChatNotice;