import '../../App.css';
import '../css/Login.css';

import React, { useState } from 'react';
import Button from '../js/Button';
import Verify from '../js/Verify';

import { Link } from 'react-router-dom';
import { baseUrl } from '../../shared';
import { login } from '../js/modules/User';

import Swal from 'sweetalert2';

function Login() {
    const queryParameters = new URLSearchParams(window.location.search);
    const redirect = queryParameters.get('redirect');

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [verifyVisible, setVerifyVisible] = useState(false);
    const [loginVisible, setLoginVisible] = useState(true);

    const loginPressed = async (e) => {
        e.preventDefault();

        const responseInfo = await login(email, password);
        if (responseInfo.message == 'Verify') {
            localStorage.setItem('success-email', email);
            localStorage.setItem('success-pswd', password);

            setLoginVisible(false);
            setVerifyVisible(true);
        } else if (responseInfo.message == 'Account Not Found') {
            Swal.fire({
                title: "Account Not Found",
                text: "Did you mean to sign up?",
                icon: "question",
                showDenyButton: true,
                confirmButtonText: "Sign Up",
                denyButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location = "./sign-up";
                };
            });
        } else if (responseInfo.message == 'Invalid Credentials') {
            Swal.fire({
                title: "Failed to Login",
                text: "Either the incorrect Email or Password was given.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'Success') {
            Swal.fire({
                title: "Success!",
                text: "You have been logged in! Welcome back " + responseInfo.data.account.rbxuser.username + ".",
                icon: "success",
                confirmButtonText: "Ok"
            }).then((result) => {
                window.location = redirect || "./"
            });

            localStorage.clear();
        }
    };

    return (
        <>
            <div className='login-container-image'/>

            {loginVisible && !localStorage.getItem('success-email') &&
                <>
                    <form className='login-form' onSubmit={loginPressed}>
                        <h3><i class="fa-solid fa-circle-info"/> Welcome Back!</h3>

                        <label for='email'><i class='fa-solid fa-at fa-xs'/> Email or Username</label>
                        <input type='text' placeholder='Enter a Valid Email or Username' id='email' value={email} onChange={(e) => {
                            setEmail(e.target.value);
                        }}/>

                        <label for='password'><i class="fa-solid fa-lock fa-xs"/> Password</label>
                        <input type='password' placeholder='Enter a Valid Password' id='password' value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }}/>

                        <Button className='login-form-submit' buttonStyle='btn--primary'>Login <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                        <Link to='/sign-up' className='signup-hint'>
                            Don't have an account? Sign up.
                        </Link>
                        <Link to='/request-reset-password' className='reset-hint'>
                            Forgot your password? Reset it.
                        </Link>
                    </form>
                </>
            }

            {(verifyVisible != false || localStorage.getItem('success-email')) && <Verify/>}
        </>
    )
};

export default Login;