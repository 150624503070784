import React, { useState } from 'react';
import '../css/TicketUserList.css';
import '../css/StaffHome.css';

import Button from '../js/Button';
import Message from '../js/Message';
import { redirectToTicket } from '../js/modules/Tickets';

function Item({ticket, panel}) {
    const [state, setState] = useState('closed');

    const lastvisit = JSON.parse(localStorage.getItem('lastvisit'));

    var ticket_date = new Date(0);
    ticket_date.setUTCSeconds(ticket.ticket_created);

    return (
        <>
            <div className='ticketlist-item' style={{'--color': panel.color}} onClick={() => {state == 'closed' ? setState('opened') : setState('closed')}}>
                <div className='ticketlist-item-img'>
                    <i className='fa-solid fa-ticket fa-l'/>
                </div>

                <div className='ticketlist-item-info'>
                    <p>Ticket #{ticket.ticket_id.substr(1, 6)}</p>
                    <p>This ticket was opened on {ticket_date.toLocaleDateString()} at {ticket_date.toLocaleTimeString()}</p>
                </div>

                <div className='ticketlist-item-actions'>
                    {(!ticket.staff_ids[0] || (Math.round(Date.now() / 1000) - ticket.ticket_created) <= (10*60) || (lastvisit[ticket.ticket_id] && ticket.messages[0] && lastvisit[ticket.ticket_id] < ticket.messages[0].time || !lastvisit[ticket.ticket_id])) && <span>{`[NEW]`}</span>}
                    {state == 'closed' ? <i class="fa-solid fa-caret-down" style={{color: '#ffffff'}}/> : <i class="fa-solid fa-caret-up" style={{color: '#ffffff'}}/>}
                </div>
            </div>

            <div className='ticketlist-item-dropdown' hidden={state == 'closed'} style={{'--color': panel.color, display: state == 'closed' ? 'none' : 'flex'}}>
                <div className='ticketlist-item-dropdown-header'>
                    <h3><i className='fa-solid fa-ticket fa-sm'/> Ticket #{ticket.ticket_id.substr(1, 6)}</h3>
                </div>

                <div className='ticketlist-item-dropdown-content'>
                    <div className='ticketlist-item-dropdown-info'>
                        <p>Last Message</p>
                        {ticket.messages[0] ? <Message extraStyle={{'--marginRight': 'auto'}} notices={ticket.messages[0].notices} width='400px' user={ticket.messages[0].user} content={ticket.messages[0].content} time={ticket.messages[0].time}/> : <p style={{fontSize: '15px'}}>No messages have been sent in this ticket yet.</p>}
                    </div>
                    
                    <div className='ticketlist-item-dropdown-btns'>
                        <Button buttonStyle='btn--primary' className='staff-btn' onClick={() => redirectToTicket(ticket.ticket_id)} buttonSize='btn--medium'>Open Ticket Chat <i className='fa-solid fa-ticket'/></Button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Item;