import React, { useState, useContext, useEffect } from 'react';
import { staffGroupID } from '../../shared';
import Button from '../js/Button';
import Popup from '../js/Popup';

import { getGroupRank } from '../js/modules/User';
import AuthContext from '../js/modules/AuthContext';
import * as Policies from '../../policies';

import '../../App.css';
import '../css/StaffHome.css';

function StaffHome() {
    const authContext = useContext(AuthContext);
    const [ staffRank, setStaffRank ] = useState('Loading');
    const [ popup, setPopup ] = useState();

    const message_date = new Date(0);
    message_date.setUTCSeconds(authContext.user.accountCreated);

    useEffect(() => {
        getGroupRank(authContext.user.rbxid, staffGroupID)
            .then((rank) => {
                setStaffRank(rank);
            });
    }, []);

    return (
        <>
            {popup &&
                <Popup onclose={() => setPopup()} title={popup.title} headerchildren={popup.headerchildren} description={popup.description} fields={popup.fields}/>
            }

            <div className='staff-home-body-container'>
                <div className='staff-home-member-info-container'>
                    <div className='staff-home-member-info-left-container'>
                        <div className='staff-home-member-info-left-staff-info-container'>
                            <img src={authContext.user.rbxuser.imageurl}/>
                            <h3>{authContext.user.rbxuser.username}</h3>
                            <span>{staffRank}</span>

                            <div id='staff-rank'>
                                <span>Account Created on {message_date.toLocaleDateString()}</span>
                            </div>
                        </div>

                        <div className='staff-home-member-info-left-staff-info-details'>
                            <div id='header'>
                                <i className='fa-solid fa-circle-info'/>
                                <h3>Support Info</h3>
                            </div>
                            <div id='body'>
                                <div className='staff-home-member-info-left-staff-info-detail'>
                                    <h3>Account ID</h3>
                                    <span>{authContext.user.rbxid}</span>
                                </div>
                                <div className='staff-home-member-info-left-staff-info-detail'>
                                    <h3>Tickets Claimed</h3>
                                    <span>{authContext.user.ticketsClaimed}</span>
                                </div>
                                <div className='staff-home-member-info-left-staff-info-detail' style={{'borderBottom': 'none'}}>
                                    <h3>Rank</h3>
                                    <span>{staffRank}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='staff-home-member-info-right-container'>
                        <div className='staff-home-member-info-right-staff-info-container'>
                            <div id='header'>
                                <i className="fa-solid fa-circle-exclamation"/>
                                <h3>Support Staff Actions</h3>
                            </div>
                            <div id='body'>
                                <Button className='staff-btn' path='/staff/tickets' buttonStyle='btn--primary'>View Tickets <i className='fa-solid fa-ticket'/></Button>
                                {authContext.user.permissionFlags.IS_ADMIN && <Button className='staff-btn' path='/admin' buttonStyle='btn--primary'>Manager View <i className='fa-solid fa-clipboard-user'/></Button>}
                                <Button className='staff-btn' path='/staff/archivedtickets' buttonStyle='btn--cancel'>View Archived Tickets <i className='fa-solid fa-ticket'/></Button>
                            </div>
                        </div>

                        <div className='staff-home-member-info-right-staff-info-container'>
                            <div id='header'>
                                <i className='fa-solid fa-circle-info'/>
                                <h3>Policy Relating to Tickets</h3>
                            </div>
                            <div id='policy-grid'>
                                <div id='policy-grid-body'>
                                    <div className='policy-grid-item' onClick={() => {setPopup({title: 'CODE OF CONDUCT', fields: Policies.CodeOfConduct.fields, headerchildren: <span id='red-notice'>POLICY</span>, description: Policies.CodeOfConduct.description})}}>
                                        <span>CODE OF CONDUCT</span>
                                        <span id='item-red'>POLICY</span>
                                    </div>
                                    <div className='policy-grid-item' onClick={() => {setPopup({title: 'TICKET APPEALS', fields: Policies.TicketAppeals.fields, headerchildren: <span id='blue-notice'>DIRECTIVE</span>, description: Policies.TicketAppeals.description})}}>
                                        <span>TICKET APPEALS</span>
                                        <span id='item-blue'>DIRECTIVE</span>
                                    </div>
                                    <div className='policy-grid-item' onClick={() => {setPopup({title: 'TICKET HANDLING', fields: Policies.TicketHandling.fields, headerchildren: <span id='red-notice'>POLICY</span>, description: Policies.TicketHandling.description})}}>
                                        <span>TICKET HANDLING</span>
                                        <span id='item-red'>POLICY</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaffHome;