import '../../App.css';
import InfoSection from '../js/InfoSection';

function Home() {
    return (
        <>
            <InfoSection/>
        </>
    )
};

export default Home;