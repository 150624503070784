import React, { useState } from "react";
import '../css/RequestResetPassword.css';

import Swal from 'sweetalert2';

import { requestPasswordReset } from "../js/modules/User";
import Button from "../js/Button";

function RequestResetPassword() {
    const [email, setEmail] = useState();
    const [status, setStatus] = useState('available');
    
    const formSubmit = async (e) => {
        e.preventDefault();
        if (!email || status != 'available') return;

        setStatus('loading');
        const response = await requestPasswordReset(email);
        if (response.message == 'Success') {
            Swal.fire({
                title: "Success!",
                text: "Check your email to continue resetting your password. You have 5 minutes to complete resetting your password, if you do not have that time currently, try resetting later.",
                icon: "success",
                confirmButtonText: "Ok"
            });
        } else if (response.message == 'Active Token') {
            Swal.fire({
                title: "Active Reset Request",
                text: "You may only have one reset request active at a time, check your email for the link including your active one.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (response.message == 'Not Found') {
            Swal.fire({
                title: "Account Not Found",
                text: "Did you input the correct email?",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else {
            Swal.fire({
                title: "Failed",
                text: "An unexpected error occurred, please try again later.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        }

        setStatus('available');
    };

    return (
        <>
            <form className='request-reset-form' onSubmit={formSubmit}>
                <h3><i class="fa-solid fa-circle-info"/> Request Password Reset</h3>

                <div className='request-reset-form-elements'>
                    <input type='email' placeholder='Enter a Valid Email' id='email' value={email} onChange={(e) => {
                        setEmail(e.target.value);
                    }}/>
                </div>

                <Button className='request-reset-form-submit' buttonStyle='btn--confirm'>Submit <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
            </form>
        </>
    );
}

export default RequestResetPassword;