import { baseUrl } from '../../../shared';
import { socket } from '../modules/Messaging';
import { useContext } from 'react'
import AuthContext from './AuthContext';

export const getUsers = async () => {
    const response = await fetch(baseUrl + 'api/accounts', {credentials: 'include'});
    return response.json();
};

export const getUser = async (id) => {
    const response = await fetch(baseUrl + `api/accounts/${id}`, {credentials: 'include'});
    return response.json();
};

export const getDiscordUser = async (discord_id) => {
    const response = await fetch(baseUrl + `api/discord/users/${discord_id}`, {credentials: 'include'});
    return response.json();
};

export const searchForUser = async (query) => {
    const response = await fetch(baseUrl + `api/accounts/search?query=${query}`, {credentials: 'include'});
    return response.json();
};

export const deleteUser = async (id) => {
    const response = await fetch(baseUrl + `api/account/delete`, {credentials: 'include', method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({id})});
    return response.json();
};

export const changePermLevel = async (id, level) => {
    const response = await fetch(baseUrl + `api/account/changePermLevel`, {credentials: 'include', method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({id, level})});
    return response.json();
};

export const getPermissionSets = async (id) => {
    const response = await fetch(baseUrl + `api/permissions`, {credentials: 'include'});
    return response.json();
};

export const getTickets = async (status) => {
    const response = await fetch(baseUrl + `api/tickets?status=${status || 'Active'}`, {credentials: 'include'});
    return response.json();
};

export const searchTickets = async (query, status) => {
    const response = await fetch(baseUrl + `api/ticket/search?query=${query}&status=${status || 'Active'}`, {credentials: 'include'});
    return response.json();
};

export const changeStatus = async (id, status) => {
    const response = await fetch(baseUrl + `api/account/changeStatus`, {credentials: 'include', method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({id, status})});
    return response.json();
};

export const syncInformation = async (id) => {
    const response = await fetch(baseUrl + `api/account/syncInformation`, {credentials: 'include', method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({id})});
    return response.json();
};

export const closeTicket = async (callback) => {
    socket.emit('closeTicket', null, callback);
};

export const claimTicket = async (callback) => {
    socket.emit('claimTicket', null, callback);
};

export const unclaimTicket = async (callback) => {
    socket.emit('unclaimTicket', null, callback);
};

export const openTicket = async (callback) => {
    socket.emit('openTicket', null, callback);
};

export const deleteTicketArchive = async (callback) => {
    socket.emit('deleteArchive', null, callback);
};

export const applyTicketSettings = async (choseTopic, accountQuery, callback) => {
    socket.emit('applyTicketSettings', {choseTopic, accountQuery}, callback);
};

export const redirectToAccountPage = async (id, redirect) => {
    if (!redirect) {
        window.location = `/admin/accounts/${id}`;
    } else {
        window.location = `/admin/accounts/${id}?redirect=${redirect}`;
    }
};

export const redirectToArchivedTickets = async (search) => {
    if (!search) {
        window.location = `/staff/archivedtickets`;
    } else {
        window.location = `/staff/archivedtickets?search=${search}`;
    }
};