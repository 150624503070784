import React, { useState, useEffect, useContext, useRef } from "react";
import AuthContext from "../js/modules/AuthContext";

import Swal from "sweetalert2";

import Notice from "../js/Notice";
import Button from "../js/Button";
import { getUsers, searchForUser, redirectToAccountPage } from '../js/modules/Admin';

import '../../App.css';
import '../css/AdminHome.css';

function AdminHome () {
    const [state, setState] = useState('loading');
    const [search, setSearch] = useState();

    const authContext = useContext(AuthContext);
    const users = useRef();

    useEffect(() => {
        getUsers()
            .then((response) => {
                if (response.message != 'Success') return setState('error');

                setState('success');
                users.current = response.data;
            })
            .catch(() => {
                setState('error');
            });
    }, []);

    const searchAccount = () => {
        if (!search) return;
        
        searchForUser(search)
            .then((response) => {
                if (response.message != 'Success') {
                    return Swal.fire({
                        title: "Account Not Found",
                        text: "Did you enter the correct details?",
                        icon: "question",
                        confirmButtonText: "Ok",
                    });
                };

                redirectToAccountPage(response.data.id);
            })
            .catch(() => {

            });
    };
    
    return (
        <>
            {state == 'loading' && 
                <div className='loading-container'>
                    <h3>Loading View</h3>
                    <div className='load-icon'/>
                </div>
            }

            {state == 'success' && users.current && users.current.length != 0 &&
                <>
                    <div id='search-bar-holder'>
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <input placeholder='Search account by email, username, discord ID, roblox ID, or account ID.' className='user-view-searchbar' value={search} onChange={(e) => setSearch(e.target.value)} onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                searchAccount();
                            }
                        }}/>
                    </div>

                    <div className='user-view-body'>
                        {users.current.map((user, _) => {
                            return (
                                <div className='user-view-container'>
                                    <img src={user.rbxuser.imageurl}/>
                                    <h3>{user.rbxuser.username}</h3>
                                    <div className='user-view-container-info-container'>
                                        <div className='user-view-container-info'><span>Roblox ID: {user.rbxid}</span></div>
                                        <div className='user-view-container-info'><span>Discord ID: {user.discordid}</span></div>
                                    </div>
                                    <Button buttonSize='btn--medium' path={`/admin/accounts/${user.id}`} className='admin-btn' buttonStyle='btn--primary'>View Account <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                                </div>
                            );
                        })}
                    </div>
                </>
            }

            {(state == 'error' || (users.current && users.current.length == 0)) &&
                <Notice title={{text: 'Unable to Load View'}} content="The page's user list view was not able to be loaded at this time, try again later." btns={[<Button path='/staff' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>   
            }
        </>
    );
};

export default AdminHome;