import React, { useContext } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import AuthContext from './modules/AuthContext';

import Tickets from '../../components/pages/Tickets';
import DisabledPage from '../pages/DisabledPage';
import OpenTicket from '../../components/pages/OpenTicket';
import PageNotFound from '../../components/pages/PageNotFound';
import Navbar from '../../components/js/Navbar';
import Home from '../../components/pages/Home';
import Login from '../../components/pages/Login';
import ArchivedTickets from '../pages/ArchivedTickets';
import SignUp from '../../components/pages/SignUp';
import UnauthorizedPage from '../../components/pages/Unauthorized';
import AdminAccountView from '../pages/AdminAccountView';
import StaffHome from '../../components/pages/StaffHome';
import OAuth from '../../components/pages/OAuth';
import AdminHome from '../../components/pages/AdminHome';
import StaffTickets from '../../components/pages/StaffTickets';
import RequestResetPassword from '../pages/RequestResetPassword';
import ResetPassword from '../pages/ResetPassword';

function RouterComponent() {
    const authContext = useContext(AuthContext);

    const GuestRoute = () => {
        if (authContext.user && authContext.user.status == 'Disabled') {
            return <DisabledPage/>
        } else {
            return <Outlet/>
        }
    };

    const ProtectedRoute = () => {
        if (authContext.user && authContext.user.status == 'Disabled') {
            return <DisabledPage/>
        } else {
            return authContext.user && authContext ? <Outlet/> : <Navigate to={`/login?redirect=${window.location}`} replace/>
        }
    };

    const StaffRoute = () => {
        return !authContext.user || !authContext.user.permissionFlags.IS_STAFF ? <UnauthorizedPage/> : <Outlet/> 
    };

    const AdminRoute = () => {
        return !authContext.user || !authContext.user.permissionFlags.IS_ADMIN ? <UnauthorizedPage/> : <Outlet/>
    };

    return (
        <>
            <Navbar/>
            <Routes>
                <Route element={<GuestRoute/>}>
                    <Route exact path='/' element={<Home/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/sign-up' element={<SignUp/>}/>
                    <Route path='/oauth' element={<OAuth/>}/>
                    <Route path='/request-reset-password' element={<RequestResetPassword/>}/>
                    <Route path='/reset-password' element={<ResetPassword/>}/>
                </Route>

                <Route element={<ProtectedRoute/>}>
                    <Route path='/tickets' element={<Tickets/>}/>
                    <Route path='/tickets/:id' element={<Tickets/>}/>
                    <Route path='/openticket' element={<OpenTicket/>}/>
                    <Route path='/openticket/:id/:topicText' element={<OpenTicket/>}/>
                </Route>

                <Route element={<StaffRoute/>}>
                    <Route path='/staff' element={<StaffHome/>}/>
                    <Route path='/staff/tickets' element={<StaffTickets/>}/>
                    <Route path='/staff/archivedtickets' element={<ArchivedTickets/>}/>
                </Route>

                <Route element={<AdminRoute/>}>
                    <Route path='/admin' element={<AdminHome/>}/>
                    <Route path='/admin/accounts/:id' element={<AdminAccountView/>}/>
                </Route>
                
                <Route path='*' element={<PageNotFound/>}/>
            </Routes>
        </>
    );
};

export default RouterComponent;