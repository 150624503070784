import { baseUrl } from '../../../shared';
import { useContext } from 'react'
import AuthContext from './AuthContext';

export const login = async (email, password) => {
    const response = await fetch(baseUrl + 'api/account/login', {method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({email, password})});
    return response.json();
};

export const logout = async () => {
    const response = await fetch(baseUrl + 'api/account/logout', {method: 'POST', credentials: 'include'});
    return response.json();
};

export const requestPasswordReset = async (email) => {
    const response = await fetch(baseUrl + 'api/account/forgotPassword', {method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({email})});
    return response.json();
};

export const resetPassword = async (token, password) => {
    const response = await fetch(baseUrl + 'api/account/resetPassword', {method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({token, password})});
    return response.json();
};

export const getGroupRank = async (rbxid, groupid) => {
    return new Promise((resolve, reject) => {
        fetch(baseUrl + `api/roblox/groupRoles/${rbxid}`, {credentials: 'include'})
            .then((response) => response.json())
            .then((response) => {
               response.data.map((groupInfo, _) => {
                    if (groupInfo.group.id == groupid) {
                        return resolve(groupInfo.role.name);
                    }
               }); 
               resolve('Guest');
            })
            .catch((err) => {
                console.log(err)
                resolve('Guest');
            });
    });
};

export const refresh = async () => {
    return new Promise((resolve, reject) => {
        fetch(baseUrl + 'api/account/me', {credentials: 'include'})
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject();
            });
    })
};
