import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';

import { getUser } from '../js/modules/Admin';
import AccountViewer from "../js/AccountViewer";
import Notice from '../js/Notice';
import Button from '../js/Button';

function AdminAccountView () {
    const { id } = useParams();
    const [state, setState] = useState('loading');
    
    const user = useRef();

    useEffect(() => {
        getUser(id)
            .then((response) => {
                if (response.message != 'Success' && !user.current) return setState('not found');

                setState('success')
                user.current = response.data;
            })
            .catch(() => {
                setState('error');
            });
    }, []);

    return (
        <>
            {state == 'loading' &&
                <div className='loading-container'>
                    <h3>Loading View</h3>
                    <div className='load-icon'/>
                </div>
            }

            {state == 'success' && user.current && <AccountViewer account={user.current}/>}
            {state == 'not found' &&
                <Notice title={{text: 'Unable to Load View'}} content="This account does not exist." btns={[<Button path='/admin' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>   
            }

            {state == 'error' && 
                <Notice title={{text: 'Unable to Load View'}} content="This account page cannot be loaded at this moment, try again later." btns={[<Button path='/admin' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>   
            }
        </>
    );
};

export default AdminAccountView;