import React, { useState } from 'react';
import Button from '../js/Button';

import '../../App.css';
import '../css/Verify.css';
import { baseUrl, DiscordOAuthUrl } from '../../shared';

import Swal from 'sweetalert2';

function Verify() {
    const [showVerify, setShowVerify] = useState(true);
    const [showChange, setShowChange] = useState(false);

    const [username, setUsername] = useState();

    const redirect = (e) => {
        e.preventDefault();
        window.location.href = DiscordOAuthUrl;
    };

    const changeUsername = async (e) => {
        e.preventDefault();

        const response = await fetch(baseUrl + 'api/account/changeRbxUser', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
            email: localStorage.getItem('success-email'),
            password: localStorage.getItem('success-pswd'),
            username: username
        })});
        const responseInfo = await response.json();

        if (responseInfo.message == 'Account Username Exists') {
            Swal.fire({
                title: "Failed to Change Username",
                text: "An existing account is already linked to this ROBLOX username: " + username + ".",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'No Roblox Account') {
            Swal.fire({
                title: "Failed to Change Username",
                text: "A ROBLOX Account with the username you supplied does not exist.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'Error') {
            Swal.fire({
                title: "Failed to Change Username",
                text: "An issue occurred on our end, please try again later.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'Success') {
            Swal.fire({
                title: "Success!",
                text: "Your accounts ROBLOX username has been changed to " + username + ".",
                icon: "success",
                confirmButtonText: "Ok"
            }).then((result) => {
                setShowChange(false)
                setShowVerify(true)
            });
        }
    };

    return(
        <>
            {showChange &&
                <>
                    <form className='username-form' onSubmit={changeUsername}>
                        <h3><i class="fa-solid fa-user fa-sm"></i> Change Username</h3>

                        <label for='username'>ROBLOX Username</label>
                        <input type='text' placeholder='Enter a Valid ROBLOX Username' id='username' value={username} onChange={(e) => {
                            setUsername(e.target.value);
                        }}/>

                        <Button className='username-form-submit' type='submit' buttonStyle='btn--confirm' buttonSize='btn--large'>Finished <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                    </form>
                </>
            }
            {showVerify &&
                <>
                    <form className='verify-form' onSubmit={redirect}>
                        <h3><i class="fa-solid fa-certificate"></i> Verify Account</h3>

                        <label for='verify-input'>After ensuring you are verified on Bloxlink in <a href='https://discord.gg/ydamod'>our discord server</a>, press the button below and we will re-direct you to Discord to verify your Discord and ROBLOX account.</label>

                        <ul className='verify-form-btns'>
                            <Button className='verify-form-submit' type='submit' buttonStyle='btn--confirm' buttonSize='btn--medium'>Verify <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                            <Button className='verify-form-change' type='button' onClick={() => {setShowVerify(false); setShowChange(true);}} buttonStyle='btn--primary' buttonSize='btn--medium'>Change Username <i class="fa-solid fa-user fa-xs"/></Button>
                        </ul>
                    </form>
                </>
            }
        </>
    )
};

export default Verify;