import React from 'react';
import Button from './Button';

import '../../App.css';
import '../css/Popup.css';

function Popup({onclose, title='No Title', contentchildren, headerchildren, description, fields}) {    
    return (
        <>
            <div className='popup-background' onClick={(e) => {
                if (e.target != e.currentTarget) return;
                onclose();
            }}>
                <div className='popup-container'>
                    <div className='popup-header'>
                        {headerchildren}
                        {title}
                    </div>

                    <div className='popup-content'>
                        {description && 
                            <div className='popup-body'>
                                <span>{description}</span>
                            </div>
                        }
                                                
                        {fields && fields.map((field, _) => {
                            return (
                                <div className='popup-field'>
                                    <h3>{field.title}</h3>
                                    <span>{field.content}</span>
                                    {field.children}
                                </div>
                            )
                        })}

                        {contentchildren}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Popup;