import React, { useState, useContext } from "react";
import '../../css/AccountOverviewPopup.css';
import AuthContext from "../modules/AuthContext";

import Swal from "sweetalert2";
import Button from "../Button";
import { redirectToAccountPage, redirectToArchivedTickets } from "../modules/Admin";

export const getFields = (account, me) => {
    return ([
        {title: 'Actions', content: 'Here are all the actions you can take.', children:
            <>
                <div className='account-overview-btns-container'>
                    {me.permissionFlags.IS_ADMIN && <Button buttonStyle='btn--primary' buttonSize='btn--medium' className='staff-btn' onClick={() => redirectToAccountPage(account.account_id, window.location)}>View Account Page <i class="fa-solid fa-arrow-right-to-bracket"/></Button>}
                    <Button buttonStyle='btn--primary' buttonSize='btn--medium' className='staff-btn' onClick={() => redirectToArchivedTickets(account.rbxuser.username)}>View Ticket Archives <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                </div>
            </>
        }
    ]);
};

function AccountOverviewPopup ({account, onclose}) {
    return (
        <></>
    );
};

export default AccountOverviewPopup;