import { io } from 'socket.io-client';
import { baseUrl } from '../../../shared';
import { messagingSocket } from './Socket';

export const joinChat = (chatid, callback) => {
    messagingSocket.emit('joinChat', {chatid}, callback);
};

export const publishMessage = (messageData, callback) => {
    messagingSocket.emit('message', messageData, callback);
};

export const deleteMessage = (messageid, callback) => {
    messagingSocket.emit('deleteMessage', {messageid}, callback);
};

export const upload = async (attachments) => {
    const body = new FormData();
    for (var i = 0; i < attachments.length; i++) {
        body.append('file', attachments[i])
    };

    const response = await fetch(baseUrl + 'api/upload', {credentials: 'include', body, method: 'POST'})
    return response.json();
};

export const socket = messagingSocket;