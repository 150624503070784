import React, { useContext } from 'react';
import '../../App.css';
import Button from './Button';
import '../css/InfoSection.css';
import AuthContext from './modules/AuthContext';

function InfoSection() {
  var authContext = useContext(AuthContext);

  return (
    <div className='info-container'>
      <h1 className='title-info'>Rome Staff Support</h1>
      <p>Support team for all things moderation.</p>
      {authContext.user &&
        <>
          <p style={{fontSize: 25 + 'px'}}>Welcome back {authContext.user.rbxuser.username}.</p>
        </>
      }
      <div className='info-btns'>
        {authContext.user &&
          <>
            <Button path='/openticket' className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>Open a Ticket</Button>
            <Button path='/tickets' className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>View Tickets <i className='fa-solid fa-ticket'/></Button>
          </>
        }

        {!authContext.user &&
          <>
            <Button path='/login' className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>Login to Get Started</Button>
          </>
        }
      </div>
    </div>
  )
};

export default InfoSection;