import React, { useState, useEffect, useContext } from 'react';
import { baseUrl } from '../../shared';
import { logout } from './modules/User';
import { Link } from 'react-router-dom';
import Button from './Button';
import AuthContext from './modules/AuthContext';
import '../css/Navbar.css';

function Navbar() {
  const authContext = useContext(AuthContext);

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const logOut = () => {
    logout()
      .then(() => {
        localStorage.clear();
        window.location = "./"
      })
      .catch(() => {
        return;
      });
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Support <i className='fab fa-typo3'/>
                </Link>

                <div className="menu-icon" onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>
                
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                      Home
                    </Link>
                  </li>

                  {authContext.user && 
                    <>
                      <li className='nav-item'>
                        <Link to='/tickets' className='nav-links' onClick={closeMobileMenu}>
                          Tickets
                        </Link>
                      </li>
                    </>
                  }

                  {authContext.user && authContext.user.permissionFlags.IS_STAFF &&
                    <>
                      <li className='nav-item'>
                        <Link to='/staff' className='nav-links' onClick={closeMobileMenu}>
                          Staff
                        </Link>
                      </li>
                    </>
                  }

                  <li className='nav-item'>
                    <Link to='/login' className='nav-links-mobile' onClick={closeMobileMenu}>
                      Login
                    </Link>
                  </li>
                </ul>
                {button && !authContext.user && <Button path='/login' buttonStyle='btn--outline'>Login</Button>}
                {button && authContext.user && <Button path='/' buttonStyle='btn--outline' onClick={logOut}>Logout</Button>}
            </div>
        </nav>
    </>
  )
}

export default Navbar;