import '../../App.css';
import React from 'react';

function Notice ({title, content, btns}) {
    return (
        <>
            <div className='msg-container'>
                <h3>{title.logo} {title.text}</h3>
                <p>{content}</p>

                <div className='msg-btn-container'>
                    {btns.map((btn, _) => {
                        return btn
                    })}
                </div>
            </div>
        </>
    )
}

export default Notice;