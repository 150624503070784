import { joinChat, publishMessage, socket, upload } from '../js/modules/Messaging';
import Swal from 'sweetalert2';

import Message from '../js/Message';
import Notice from '../js/Notice';
import Button from '../js/Button';
import Attachment from '../js/Attachment';
import Popup from './Popup';
import AccountOverviewPopup, { getFields } from './popups/AccountOverviewPopup';
import TicketActions from '../js/TicketActions';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { isElementLoaded } from '../../shared';
import ChatNotice from '../js/ChatNotice';
import AuthContext from '../js/modules/AuthContext';

import '../../App.css';
import '../css/Chat.css';

function Chat({chatid, backToPath}) {
    const [state, setState] = useState('loading');
    const [popup, setPopup] = useState();
    const [messageState, setMessageState] = useState('');

    const [attachments, setAttachments] = useState([]);

    const [chatHistory, setChatHistory] = useState([]);
    const [chatBar, setChatBar] = useState('');

    const [notifyRecipient, setNotifyRecipient] = useState(false);

    const lastvisit = JSON.parse(localStorage.getItem('lastvisit'));
    const authContext = useContext(AuthContext);

    const loadedMessages = useRef();
    loadedMessages.current = 0;

    const ticketInfo = useRef();
    const permissions = useRef();

    const scrollToBottom = (delay) => setTimeout(async () => {
        const messageEnd = await isElementLoaded('#chat-message-end');
        messageEnd.scrollIntoView(false);
    }, delay);

    useEffect(() => {
        joinChat(chatid, async (response) => {
            if (response.status == 'ok') {
                setChatHistory(response.chatHistory);

                lastvisit[response.ticketInfo.ticket_id] = Math.round(Date.now() / 1000);
                localStorage.setItem('lastvisit', JSON.stringify(lastvisit));

                ticketInfo.current = response.ticketInfo;
                permissions.current = response.permissions;

                if (!permissions.current.canChat && !permissions.current.canClaim && !permissions.current.canClose) {
                    Swal.fire({text: 'You are not permitted to speak in this chat, but you may still view it.', icon: 'info', confirmButtonText: 'Ok'});
                }; 

                socket.off('switchPanel');
                socket.off('switchTicketOwner');
                socket.off('message');
                socket.off('openTicket');
                socket.off('closeTicket');
                socket.off('deleteArchive');
                socket.off('deleteMessage');
                socket.off('leaveChat');
                socket.on('leaveChat', (info) => {
                    if (info.ticket_id != ticketInfo.current.ticket_id) return;
                    setState('chat ended');
                });
                socket.on('message', (info) => {
                    if (info.ticket.ticket_id != ticketInfo.current.ticket_id) return;
                    setChatHistory((state) => {
                        return [info.message, ...state];
                    });
                });
                socket.on('deleteMessage', (info) => {
                    if (info.ticket.ticket_id != ticketInfo.current.ticket_id) return;
                    setChatHistory((state) => {
                        const message_index = state.findIndex((message) => {return message.message_id == info.message_id});
                        console.log(message_index)
                        if (message_index >= 0) {
                            state.splice(message_index, 1);
                            return [...state];
                        } else {
                            return state;
                        }
                    })
                });
                socket.on('closeTicket', (message) => {
                    if (authContext.user.id != message.closer_id) window.location.reload();
                });
                socket.on('openTicket', (message) => {
                    if (authContext.user.id != message.opener_id) window.location.reload();
                });
                socket.on('deleteArchive', (message) => {
                    if (authContext.user.id != message.deleter_id) window.location.reload();
                });
                socket.on('switchPanel', (message) => {
                    if (authContext.user.id != message.switcher_id) window.location.reload();
                });
                socket.on('switchTicketOwner', (message) => {
                    if (authContext.user.id != message.switcher_id) window.location.reload();
                });
            }
            setState(response.status);
        });

        return () => {
            socket.off('message');
        };
    }, []);

    useEffect(() => {
        scrollToBottom(100);
    }, [chatHistory, attachments, messageState]);
    
    const message = () => {
        if (chatBar == '' || chatBar.length == 0) return;
        if (attachments.length > 2) return Swal.fire({title: "Failed to Upload", text: "You have the maximum amount of attachments uploaded.", icon: "error", confirmButtonText: "Ok"});
        if (messageState == 'sending' || messageState == 'processing' || messageState == 'closing') return;

        var map = {'amp': '&', 'lt': '<', 'gt': '>', 'quot': '"', '#039': "'"};
        var filtered = chatBar.replace(/&([^;]+);/g, (m, c) => map[c] || c);

        const message = {content: filtered};
        if (message.content.length > 2000) {
            Swal.fire({title: "Failed to Send", text: "Your message is too long, it can be a maximum of 2,000 characters.", icon: "error", confirmButtonText: "Ok"});
            return setMessageState('error');
        }

        if (attachments.length > 0) {
            setMessageState('processing');
            upload(attachments)
                .then((response) => {
                    if (response.message != 'Success') return Swal.fire({title: 'Failed to Upload', text: 'Your attachments are too large.', icon: 'error', confirmButtonText: 'Ok'}).then(() => setMessageState('error'));
                    message.attachments = response.data;
                    message.notices = [];

                    message.notify = notifyRecipient;
                    const checkBox = document.querySelector('#notify-recipient-checkbox');

                    setMessageState('sending');
                    publishMessage(message, (messageResponse) => {
                        if (messageResponse.status == 'ok') {
                            setChatBar('');
                            document.querySelector('#input-text-message').innerHTML = '';
                            if (checkBox) {
                                document.querySelector('#notify-recipient-checkbox').checked = false
                            }
                            setAttachments([]);
                            setMessageState('');
                            setNotifyRecipient(false);
                        } else {
                            setMessageState('error');
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setMessageState('error');
                });
        } else {
            setMessageState('sending');

            message.notify = notifyRecipient;
            const checkBox = document.querySelector('#notify-recipient-checkbox');
            
            publishMessage(message, (messageResponse) => {
                if (messageResponse.status == 'ok') {
                    setChatBar('');
                    document.querySelector('#input-text-message').innerHTML = '';
                    if (checkBox) {
                        document.querySelector('#notify-recipient-checkbox').checked = false
                    }
                    setAttachments([]);
                    setMessageState('');
                    setNotifyRecipient(false);
                } else {
                    setMessageState('error');
                }
            });
        };
    };

    const uploadAttachment = (e) => {
        if (e.target.files.length == 0) return;
        if (e.target.files.length > 2) return Swal.fire({title: "Failed to Upload", text: "You have the maximum amount of attachments uploaded.", icon: "error", confirmButtonText: "Ok"}); 
        if (attachments.length == 2) return Swal.fire({title: "Failed to Upload", text: "You have the maximum amount of attachments uploaded.", icon: "error", confirmButtonText: "Ok"}); 

        setAttachments((state) => {
            return [...e.target.files, ...state]
        });
    };

    const deleteAttachment = (file) => {
        setAttachments((state) => {
            const index = state.indexOf(file);
            if (index != -1) {
                state.splice(index, 1);
                document.getElementById('chat-file-upload').value = '';
            }
            return [...state];
        });
    };  

    const messageLoaded = () => {
        loadedMessages.current += 1
        if (loadedMessages == ticketInfo.current.messages.length) scrollToBottom(0);
    };

    const messageClicked = (account) => {
        if (authContext.user.permissionFlags.IS_STAFF) {
            setPopup({title: account.rbxuser.username, headerchildren: <span id='blue-notice'>ADMIN</span>, fields: getFields(account, authContext.user), contentchildren: <AccountOverviewPopup onclose={() => setPopup()} account={account}/>})
        };
    };
    
    return (
        <>
            {popup &&
                <Popup onclose={() => setPopup()} title={popup.title} contentchildren={popup.contentchildren} headerchildren={popup.headerchildren} description={popup.description} fields={popup.fields}/>
            }

            {state == 'loading' &&
                <>
                    <div className='loading-container'>
                        <h3>Loading Chat</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {state == 'chat ended' &&
                <Notice title={{text: 'Chat Disconnected'}} content='You have been disconnected from this ticket chat due to having multiple ticket chats open.' btns={[<Button path='/tickets' buttonSize='btn--medium' buttonStyle='btn--danger'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>
            }
            
            {state == 'ok' && chatHistory && ticketInfo.current &&
                <>
                    <div className='chat-msg-outer-container'>
                        <div className='chat-outer-header' onClick={(e) => {
                            if (e.target != e.currentTarget) return;
                            window.location = backToPath;
                        }}>
                            <h3 onClick={() => window.location = backToPath}><i class="fa-solid fa-arrow-left-long"/> Ticket #{ticketInfo.current.ticket_id.substring(1, 6)} Chat</h3>
                            {(permissions.current.canClose || permissions.current.canOpen) && <TicketActions setPopup={setPopup} extraStyle={{'marginLeft': 'auto'}} ticket={ticketInfo.current} chatMessageStatus={setMessageState} permissions={permissions.current}/>}
                        </div>
                        <span className='chat-msg-outer-header-warning'><i class="fa-solid fa-circle-exclamation"/> All Messages Are Monitored</span>

                        <div className='chat-msg-content-container' id='chat-container'>
                            <ChatNotice color='rgb(245, 164, 66, 0.7)' sections={
                                [{logo: 'fa-solid fa-ticket', title: `${ticketInfo.current.panel.title} System`, fields: [{title: 'Rules on Evidence', content: `Please make sure that any evidence you provide is:\n• Clear & high quality\n• Relevant to your appeal\n• Unedited`}, {title: 'Accepted Image/Video Formats', content: 'We accept the following formats for image submissions:\n• Gyazo links\n• Imgur links\n• prnt.sc links\n• Discord media links or direct uploads\n\nWe accept the following formats for video submissions:\n• YouTube links\n• Medal.tv links\n• Discord media links'}]},
                                 {logo: 'fa-solid fa-ticket', title: 'Questionnaire', fields: [{title: 'Roblox Account', logo: ticketInfo.current.account.rbxuser.imageurl, content: <a target='_blank' href={`https://www.roblox.com/users/${ticketInfo.current.account.rbxid}/profile`}>{ticketInfo.current.account.rbxuser.username} ({ticketInfo.current.account.rbxid})</a>}, {title: 'Discord ID', content: ticketInfo.current.account.discordid}, {title: 'Ticket Topic', content: ticketInfo.current.topic}, ...ticketInfo.current.question_responses.map((response, _) => {
                                    return {title: response.question, content: response.value}
                                 })]}]
                            }/>
                            {chatHistory.toReversed().map((message, _) => {
                                return (
                                    <>
                                        <div className='chat-msg-container'>
                                            {message.user.account_id != 'Bot' && message.user.account_id == authContext.user.id ? <Message id={message.message_id} canDelete={(permissions.current.canDeleteMessages || message.user.account_id == authContext.user.id) && message.user.account_id != 'Bot'} onMessageClick={messageClicked} width='50%' notices={message.notices} user={message.user} content={message.content} time={message.time} order='reverse' extraStyle={{'--justifyContent': 'right', '--marginLeft': 'auto'}} children={message.attachments.length > 0 && 
                                                <>
                                                    <div className='chat-msg-attachment-container'>
                                                        {message.attachments.map((attachment, _) => {
                                                            return(<Attachment upload={false} file={attachment.url}/>)
                                                        })}
                                                    </div>
                                                </>
                                            }/> : message.user.account_id != 'Bot' && <Message id={message.message_id} canDelete={(permissions.current.canDeleteMessages || message.user.account_id == authContext.user.id) && message.user.account_id != 'Bot'} width='50%' onMessageClick={messageClicked} user={message.user} notices={message.notices} content={message.content} time={message.time} extraStyle={{'--marginRight': 'auto'}} children={message.attachments.length > 0 && 
                                                <>
                                                    <div className='chat-msg-attachment-container'>
                                                        {message.attachments.map((attachment, _) => {
                                                            return(<Attachment upload={false} file={attachment.url}/>)
                                                        })}
                                                    </div>
                                                </>}/>
                                            }

                                            {message.notices.map((notice, _) => {
                                                return <ChatNotice color={notice.color} sections={notice.sections}/>
                                            })}
                                        </div>
                                        {messageLoaded()}
                                    </>
                                )
                            })}
                            <div id='chat-message-end'/>
                        </div>

                        {messageState == 'sending' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Sending Message <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'closing' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Closing Ticket <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'deleting' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Deleting Archive <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'claiming' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Claiming Ticket <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'unclaiming' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Unclaiming Ticket <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'processing' &&
                            <>
                                <div className='chat-msg-notice'>
                                    <span>Processing Attachments <i class="fa-solid fa-spinner"/></span>
                                </div>
                            </>
                        }

                        {messageState == 'error' &&
                            <>
                                <div className='chat-msg-error'>
                                    <span>Message Failed to Send</span>
                                </div>
                            </>
                        }

                        {attachments.length > 0 &&
                            <>
                                <div className='chat-attachment-container'>
                                    {Array.from(attachments).map((attachment, _) => {
                                        return <Attachment upload={true} deletedCallback={() => deleteAttachment(attachment)} file={attachment}/>
                                    })}
                                </div>
                            </>
                        }

                        <div className='chat-msg-input-outer-container'>
                            {permissions.current.canChat &&
                                <>
                                    <div className='chat-msg-input-inner-container'>
                                        <div className='chat-msg-input-inner-container-top-elements'>
                                            <input type='file' id='chat-file-upload' hidden onChange={uploadAttachment} accept='.jpg, .jpeg, .png'/>
                                            <i className='fa-solid fa-circle-plus fa-xl' onClick={(e) => document.getElementById('chat-file-upload').click()}/>
                                        </div>

                                        <span id='input-text-message' autoFocus role='textbox' contentEditable spellCheck="true" onPaste={(e) => {
                                            e.preventDefault();
                                            document.execCommand('insertText', false, e.clipboardData.getData('text/plain'));
                                        }} onKeyDown={(e) => {
                                            if (e.key == 'Enter') {
                                                e.preventDefault();
                                                message();
                                            }
                                        }} onKeyUp={(e) => {
                                            setChatBar(e.target.innerHTML);
                                        }}/>
                                    </div>
                                </>
                            } 
                            {ticketInfo.current.status == 'Archived' &&
                                <>
                                    <div className='archived-input-inner-container'>
                                        <p>This ticket has been closed and archived.</p>
                                    </div>
                                </>
                            }
                            {authContext.user.permissionFlags.IS_STAFF && ticketInfo.current.status == 'Active' &&
                                <>
                                    <div className='other-chat-options'>
                                        <input id='notify-recipient-checkbox' type='checkbox' defaultChecked={notifyRecipient} onChange={(e) => setNotifyRecipient(e.target.checked)}/>
                                        <span>Send ticket owner a notification of my message through Roman Systems on Discord.</span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
            }
            
            {state == 'insufficient permissions' && <Notice title={{text: 'Unable to Connect to Chat'}} content='You are not permitted to view this ticket chat.' btns={[<Button path='/tickets' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>}
            {state == 'invalid' && <Notice title={{text: 'Unable to Connect to Chat'}} content='We were unable to locate this ticket chat. The ticket may no longer exist.' btns={[<Button path='/tickets' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>}
        </>
    )
};

export default Chat;