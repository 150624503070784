import { baseUrl } from "../../../shared";

export const getMyTickets = async () => {
    const response = await fetch(baseUrl + 'api/account/me/tickets', {credentials: 'include'});
    return response.json();
};

export const groupTicketsByPanel = async (tickets) => {
    let grouped_tickets = {};

    tickets.map((ticket, _) => {
        if (!grouped_tickets[ticket.panel.title]) {
            grouped_tickets[ticket.panel.title] = {panelInfo: ticket.panel, tickets: []};
        }

        grouped_tickets[ticket.panel.title].tickets.push(ticket);
    });
    
    return grouped_tickets
};

export const redirectToTicket = async (ticket_id, isStaff, isArchive) => {
    if (isArchive) {
        return window.location = `/tickets/${ticket_id}?archive=true`
    }

    if (isStaff) {
        window.location = `/tickets/${ticket_id}?staff=true`;
    } else {
        window.location = `/tickets/${ticket_id}`;
    }
};

export const redirectToTicketCreation = async (panel_id, topic) => {
    window.location = `/openticket/${panel_id}/${topic}`;
};

export const getPanels = async () => {
    const response = await fetch(baseUrl + 'api/tickets/panels', {credentials: 'include'});
    return response.json();
};

export const getPanel = async (panel_id) => {
    const response = await fetch(baseUrl + 'api/tickets/panels/' + panel_id, {credentials: 'include'});
    return response.json();
};

export const getTicketPermissions = async (ticket_id) => {
    const response = await fetch(baseUrl + `api/tickets/${ticket_id}/permissions`, {credentials: 'include'});
    return response.json();
};

export const openTicket = async (panel_id, topic, question_responses) => {
    const response = await fetch(baseUrl + 'api/ticket/new', {method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: 'include', body: JSON.stringify({
        panel_id, topic, question_responses
    })});
    
    return response.json();
};