import '../../App.css';
import '../css/OAuth.css';

import { baseUrl, DiscordOAuthUrl } from '../../shared';
import Swal from 'sweetalert2';
import Button from '../js/Button';

import { useEffect } from 'react';

function OAuth() {
    const params = (new URL(document.location)).searchParams;
    const success = params.get('success');

    const login = async (e) => {
        e.preventDefault();

        const response = await fetch(baseUrl + 'api/account/login', {method: 'POST', headers: {'Content-Type': 'application/json'}, credentials: "include", body: JSON.stringify({
            email: localStorage.getItem('success-email'),
            password: localStorage.getItem('success-pswd')
        })});
        const responseInfo = await response.json();

        if (responseInfo.message == 'Verify') {
            Swal.fire({
                title: "Not Verified",
                text: "You have not verified your account. Discord or Bloxlink could be experiencing issues.",
                icon: "question",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'Account Not Found' || responseInfo.message == 'Invalid Credentials' || responseInfo.message == 'Error') {
            Swal.fire({
                title: "Failed to Verify",
                text: "An issue occurred when attempting to log in to your account.",
                icon: "error",
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location = "./";
                };
            });
        } else if (responseInfo.message == 'Success') {
            Swal.fire({
                title: "Success!",
                text: "You have been logged in! Welcome " + responseInfo.data.account.rbxuser.username + ".",
                icon: "success",
                confirmButtonText: "Ok"
            }).then((result) => {
                window.location = "./"
            });

            localStorage.clear();
            localStorage.setItem('account', JSON.stringify(responseInfo.data.account))
        }
    };
    
    const redirect = (e) => {
        e.preventDefault();
        window.location.href = DiscordOAuthUrl;
    };

    useEffect(() => {
        if (!localStorage.getItem('success-email')) {
            window.location = './'   
        };
    }, []);

    return (
        <>
            <div className='oauth-container-image'/>
            
            {success == 'true' && 
                <>
                    <form className='oauth-form' onSubmit={login}>
                        <h3><i class="fa-solid fa-circle-check"/> Success!</h3>

                        <label for='oauth-input'>We communicated with Discord and Bloxlink and have successfully verified your account. Welcome to Rome Staff Support!</label>
                        <Button className='oauth-form-submit' buttonStyle='btn--confirm'>Login <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                    </form>
                </>
            }
            
            {!success && 
                <>
                    <form className='oauth-form' onSubmit={redirect}>
                        <h3><i class="fa-solid fa-circle-exclamation"/> Failed</h3>

                        {params.get('account') && <label for='oauth-input'>Are you linked to the same account on Bloxlink in the <a href='https://discord.gg/PgUChURpvM'>Roman Empire Moderation Server</a> as you entered on this site?</label>}
                        {params.get('bloxlink') && <label for='oauth-input'>We had issues locating you on Bloxlink. Are you verified on Bloxlink in the <a href='https://discord.gg/PgUChURpvM'>Roman Empire Moderation Server</a>?</label>}
                        {params.get('discord') && <label for='oauth-input'>We had issues locating you on Discord. Discord's API may be unavailable for verification.</label>}
                        {params.get('ended') && <label for='oauth-input'>Failed to achieve access to account information through Discord. Did you complete the form?</label>}
                        
                        <div className='oauth-form-btns'>
                            <Button className='oauth-form-submit-secondary' buttonStyle='btn--cancel'>Retry <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                            <Button className='oauth-form-submit-third' onClick={() => {window.location = './login'}} type='button' buttonStyle='btn--primary'>Back <i class="fa-solid fa-rotate-left"/></Button>
                        </div>
                    </form>
                </>
            }
        </>
    )
};

export default OAuth;