import React from 'react';
import '../../App.css';
import '../css/PageNotFound.css';
import Button from '../js/Button';

function PageNotFound() {
    return (
        <>
            <div className='pagenotfound-container-image'/>
            <h3 className='pagenotfound-header'>Page Not Found</h3>
            <p className='pagenotfound-notice'>We were unable to locate this page for you.</p>

            <Button className='pagenotfound-btn' buttonStyle='btn--info' path='/'>Back to Home</Button>
        </>
    )
};

export default PageNotFound;