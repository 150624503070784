import React, { useState, useEffect, useRef, useContext } from 'react';
import { redirectToTicket, groupTicketsByPanel } from './modules/Tickets';
import { getTickets } from './modules/Admin'

import '../../App.css';
import '../css/TicketUserList.css';
import '../css/Tickets.css';

import Button from './Button';
import AuthContext from './modules/AuthContext';
import TicketStaffListItem from './TicketStaffListItem';

function TicketStaffList() {
    const authContext = useContext(AuthContext);
    const [state, setState] = useState('loading');
    const tickets = useRef();

    useEffect(() => {
        getTickets()
            .then(async (response) => {
                if (response.message != 'Success') return setState('not found');

                const groupedTickets = await groupTicketsByPanel(response.data);

                setState('success');
                tickets.current = groupedTickets;
            })
            .catch(() => {
                setState('error');
            });
    }, []);

    return (
        <>
            {state == 'loading' && 
                <>
                    <div className='loading-container'>
                        <h3>Loading Tickets</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {state == 'success' && tickets.current &&
                <>
                    <div className='ticketlist-container'>
                        <div className='ticketlist-info'>
                            <h3><i className='fa-solid fa-circle-info'/> Tickets</h3>
                            <p>Listed below are the active tickets.</p>
                            <p>If you'd like to access archived tickets, go back to staff home.</p>
                        </div>

                        <div className='ticketlist-content'>
                            {Object.keys(tickets.current).map((key, index) => {
                                if (tickets.current[key].panelInfo.staff_permLevel <= authContext.user.permissionLevel) {
                                    return(
                                        <div className='ticketlist-content-header' style={{'--color': tickets.current[key].panelInfo.color}}>
                                            <h3><i className='fa-solid fa-ticket fa-l'/> {key} Ticket System</h3>
                                            <hr/>

                                            {tickets.current[key].tickets.map((ticket, _) => {
                                                return(<TicketStaffListItem ticket={ticket} panel={tickets.current[key].panelInfo}/>)
                                            })}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </>
            }

            {state == 'not found' &&
                <>
                    <div className='ticket-not-found'>
                        <h3><i className='fa-solid fa-circle-info'/> No Tickets Found</h3>
                        <p>There are currently no open tickets.</p>

                        <div className='ticket-not-found-btns-container'>
                            <Button className='ticket-not-found-submit-third' onClick={() => {window.location = './'}} type='button' buttonStyle='btn--primary'>Back <i class="fa-solid fa-rotate-left"/></Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default TicketStaffList;