import React, { useState, useEffect, useRef, useContext } from 'react';
import { redirectToTicket, groupTicketsByPanel } from './modules/Tickets';
import { getTickets, searchTickets } from './modules/Admin'

import '../../App.css';
import '../css/TicketUserList.css';
import '../css/Tickets.css';

import Swal from 'sweetalert2';
import Button from './Button';
import AuthContext from './modules/AuthContext';
import TicketArchivedListItem from './TicketArchivedListItem';

function TicketArchivedList() {
    const query = new URLSearchParams(window.location.search);
    const pre_search = query.get('search');
    
    const authContext = useContext(AuthContext);
    const [search, setSearch] = useState(pre_search);

    const [state, setState] = useState('loading');
    const tickets = useRef();

    useEffect(() => {
        getTickets('Archived')
            .then(async (response) => {
                if (response.message != 'Success') return setState('not found');

                const groupedTickets = await groupTicketsByPanel(response.data);

                setState('success');
                tickets.current = groupedTickets;

                if (pre_search) {
                    setTimeout(searchReturn, 500);
                }
            })
            .catch(() => {
                setState('error');
            });
    }, []);

    const searchReturn = () => {
        if (!search) return window.location = '/staff/archivedtickets';

        if (window.history.pushState) {
            window.history.pushState({}, null, `/staff/archivedtickets?search=${search}`)
        }

        setState('loading');

        searchTickets(search, 'Archived')
            .then(async (response) => {
                if (response.message != 'Success') {
                    setState('success');
                    return Swal.fire({
                        title: "No Archives Found",
                        text: "Did you enter the correct details?",
                        icon: "question",
                        confirmButtonText: "Ok",
                    });
                } else {
                    const groupedTickets = await groupTicketsByPanel(response.data);
                    tickets.current = groupedTickets;

                    setState('success');

                    Swal.fire({
                        title: "Success!",
                        text: "Listed below are the archives related to your search.",
                        icon: "success",
                        confirmButtonText: "Ok"
                    });
                }
            })
            .catch(() => {
                setState('success');
                Swal.fire({
                    title: "Failed to Search",
                    text: "There was an issue while trying to search the archives relating to your search.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            });
    };

    return (
        <>
            {state == 'loading' && 
                <>
                    <div className='loading-container'>
                        <h3>Loading Archives</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {state == 'success' && tickets.current &&
                <>
                    <div className='ticketlist-container'>
                        <div className='ticketlist-info'>
                            <h3><i className='fa-solid fa-circle-info'/> Ticket Archives</h3>
                            <p>Listed below are the 50 latest archived tickets.</p>
                            <p>If you'd like to access active tickets, go back to staff home.</p>
                        </div>

                        <div style={{'marginTop': '20px', 'width': '50%'}} id='search-bar-holder'>
                            <i class="fa-solid fa-magnifying-glass"></i>
                            <input style={{'width': '100%'}} placeholder='Search archives by email, username, discord ID, roblox ID, account ID, or ticket ID.' className='user-view-searchbar' value={search} onChange={(e) => setSearch(e.target.value)} onKeyUp={(e) => {
                                if (e.key == 'Enter') {
                                    searchReturn();
                                }
                            }}/>
                        </div>

                        <div className='ticketlist-content'>
                            {Object.keys(tickets.current).map((key, index) => {
                                console.log(tickets.current[key].panelInfo.staff_permLevel, authContext.user.permissionLevel)
                                if (tickets.current[key].panelInfo.staff_permLevel <= authContext.user.permissionLevel) {
                                    return(
                                        <div className='ticketlist-content-header' style={{'--color': tickets.current[key].panelInfo.color}}>
                                            <h3><i className='fa-solid fa-ticket fa-l'/> {key} Ticket System</h3>
                                            <hr/>

                                            {tickets.current[key].tickets.map((ticket, _) => {
                                                return(<TicketArchivedListItem ticket={ticket} panel={tickets.current[key].panelInfo}/>)
                                            })}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </>
            }

            {state == 'not found' &&
                <>
                    <div className='ticket-not-found'>
                        <h3><i className='fa-solid fa-circle-info'/> No Archives Found</h3>
                        <p>There are currently no available ticket archives.</p>

                        <div className='ticket-not-found-btns-container'>
                            <Button className='ticket-not-found-submit-third' onClick={() => {window.location = './'}} type='button' buttonStyle='btn--primary'>Back <i class="fa-solid fa-rotate-left"/></Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default TicketArchivedList;