import React, { useState } from 'react';
import '../css/Panels.css';
import '../../App.css';

import { redirectToTicketCreation } from '../js/modules/Tickets';
import tinycolor from 'tinycolor2';
import Dropdown from '../js/Dropdown';
import Button from '../js/Button';
import Swal from 'sweetalert2';
import Select, { components } from 'react-select';
import { dropdown_styles } from '../../shared';

function PanelItem(props) {
    const [topicValue, setTopic] = useState(false);
    const panelSelected = (panel_id) => {
        if (topicValue == false) {
            return Swal.fire({title: 'Missing Information', text: 'You need to pick a topic before proceeding!', icon: 'error', confirmButtonText: 'Ok'});
        }

        redirectToTicketCreation(panel_id, topicValue);
    };

    return (
        <>
            <li className='panel__item'>
                <div className='panel__header' style={{'--color': props.color}}>
                    <h3 style={{'--color': tinycolor(props.color).darken(30)}}>{props.title}</h3>
                    <i className='fa-solid fa-ticket fa-2xl' style={{'--color': tinycolor(props.color).darken(30)}}/>
                </div>

                <div className='panel__container'>
                    <h3 style={{'--color': tinycolor(props.color).darken(30)}}><i class="fa-regular fa-circle-question"></i> When should I create a ticket in this category?</h3>
                    <p>{props.description}</p>

                    <h3 style={{'--color': tinycolor(props.color).darken(30)}}><i className='fa-solid fa-circle-info'/> Please select the topic of your ticket below.</h3>

                    <Select components={{Option: (props) => <components.Option {... props}><img src={props.data.image} style={{height: '20px', width: '20px', borderRadius: '50%', marginRight: '10px'}}/>{props.data.label}</components.Option>}} onChange={(state) => setTopic(state.value)} placeholder='Select Topic...' styles={dropdown_styles} options={props.topics.map((topic, _) => {
                        if (topic.public_visible) {
                            return {value: topic.text, label: topic.text, image: topic.logo}
                        }
                    })}/>

                    <Button className='panel__btn' onClick={() => {panelSelected(props.panelid)}} buttonStyle='btn--confirm' buttonSize='btn--medium'>Fill Out Ticket <i class="fa-solid fa-arrow-right-to-bracket"/></Button>

                    {props.publicvisible == 'false' &&
                        <div className='panel__footer'>
                            <span style={{'color': '#428df5'}}><i className='fa-solid fa-circle-info'/></span> 
                            <span>You can see this panel due to your permission level.</span>
                        </div>
                    }
                </div>
            </li>
        </>
    )
};

export default PanelItem;