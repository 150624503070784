import React, { useState, useEffect, useRef } from 'react';
import { getMyTickets, redirectToTicket, groupTicketsByPanel } from './modules/Tickets';

import '../../App.css';
import '../css/TicketUserList.css';
import '../css/Tickets.css';

import Button from './Button';
import TicketUserListItem from './TicketUserListItem';

function TicketUserList() {
    const [state, setState] = useState('loading');
    const tickets = useRef();

    useEffect(() => {
        getMyTickets()
            .then(async (response) => {
                if (response.message != 'Success') return setState('not found');

                const groupedTickets = await groupTicketsByPanel(response.data);

                setState('success');
                tickets.current = groupedTickets;
            })
            .catch(() => {
                setState('error');
            });
    }, []);

    return (
        <>
            {state == 'loading' && 
                <>
                    <div className='loading-container'>
                        <h3>Loading Tickets</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {state == 'success' && tickets.current &&
                <>
                    <div className='ticketlist-container'>
                        <div className='ticketlist-info'>
                            <h3><i className='fa-solid fa-circle-info'/> My Tickets</h3>
                            <p>Listed below are your active tickets.</p>
                            <p>If you'd like to access your archived tickets, please contact management.</p>
                        </div>

                        <div className='ticketlist-content'>
                            {Object.keys(tickets.current).map((key, index) => {
                                return(<div className='ticketlist-content-header' style={{'--color': tickets.current[key].panelInfo.color}}>
                                    <h3><i className='fa-solid fa-ticket fa-l'/> {key} Ticket System</h3>
                                    <hr/>

                                    {tickets.current[key].tickets.map((ticket, _) => {
                                        return(<TicketUserListItem ticket={ticket} panel={tickets.current[key].panelInfo}/>)
                                    })}
                                </div>)
                            })}
                        </div>
                    </div>
                </>
            }

            {state == 'not found' &&
                <>
                    <div className='ticket-not-found'>
                        <h3><i className='fa-solid fa-circle-info'/> No Tickets Found</h3>
                        <p>You haven't opened any tickets yet.</p>

                        <div className='ticket-not-found-btns-container'>
                            <Button className='ticket-not-found-submit-secondary' onClick={() => {window.location = './openticket'}} buttonStyle='btn--confirm'>Open a Ticket <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                            <Button className='ticket-not-found-submit-third' onClick={() => {window.location = './'}} type='button' buttonStyle='btn--primary'>Back <i class="fa-solid fa-rotate-left"/></Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default TicketUserList;