import React, { useState, useContext, useRef, useEffect } from 'react';
import '../css/TicketActions.css';

import Swal from 'sweetalert2';
import Button from './Button';
import AuthContext from './modules/AuthContext';
import { claimTicket, closeTicket, openTicket, unclaimTicket, deleteTicketArchive, switchPanel } from '../js/modules/Admin';
import { getTicketPermissions } from '../js/modules/Tickets'
import TicketSettingsPopup, { getFields } from './popups/TicketSettingsPopup';

function TicketActions ({ticket, extraStyle, setPopup, chatMessageStatus}) {
    const authContext = useContext(AuthContext);
    const [state, setState] = useState('loading');

    const permissions = useRef();

    useEffect(() => {
        getTicketPermissions(ticket.ticket_id)
            .then((response) => {
                if (response.message == 'Success') {
                    permissions.current = response.data;
                    setState('available');
                };
            })
            .catch(() => {
                return;
            });
    }, [state]);

    const unclaim = () => {
        chatMessageStatus('unclaiming');
        unclaimTicket((response) => {
            chatMessageStatus('');
            setState('unclaimed');

            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have unclaimed this ticket.",
                    icon: "success",
                    confirmButtonText: "Ok"
                });
            } else {
                Swal.fire({
                    title: "Failed to Unclaim Ticket",
                    text: "There was an issue while trying to unclaim this ticket, the ticket may be closed.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    const claim = () => {
        chatMessageStatus('claiming');
        claimTicket((response) => {
            chatMessageStatus('');
            setState('claimed');

            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have claimed this ticket.",
                    icon: "success",
                    confirmButtonText: "Ok"
                });
            } else {
                Swal.fire({
                    title: "Failed to Claim Ticket",
                    text: "There was an issue while trying to claim this ticket, it could have already been claimed or the ticket is closed.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    const close = (e) => {
        chatMessageStatus('closing');
        closeTicket((response) => {
            chatMessageStatus('');
            setState('closed');

            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have closed this ticket.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: "Failed to Close Ticket",
                    text: "There was an issue while trying to close this ticket, it could have already been closed.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    const deleteArchive = (e) => {
        chatMessageStatus('deleting');
        deleteTicketArchive((response) => {
            chatMessageStatus('');
            setState('closed');

            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have deleted this archive, it is unable to be undone.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then(() => {
                    window.location = '/staff/tickets'
                });
            } else {
                Swal.fire({
                    title: "Failed to Delete Archive",
                    text: "There was an issue while trying to delete this archive, it may no longer exist.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    const open = (e) => {
        chatMessageStatus('opening');
        openTicket((response) => {
            chatMessageStatus('');
            setState('open');

            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have opened this ticket.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: "Failed to Open Ticket",
                    text: "There was an issue while trying to open this ticket.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    const switchTicketPanel = (e) => {
        setPopup({title: 'Ticket Settings', headerchildren: <span id='blue-notice'>STAFF</span>, fields: [], contentchildren: <TicketSettingsPopup ticket_perms={permissions.current} ticket={ticket} onclose={() => setPopup()}/>})
    };

    return (
        <>
            {permissions.current &&
                <>
                    <div style={extraStyle} className='ticket-actions-container'>
                        {(permissions.current.canSwitchPanel || permissions.current.canSwitchTicketOwner) &&
                            <Button className='ticket-actions-button' buttonStyle='btn--primary' buttonSize='btn--medium' onClick={switchTicketPanel}>Ticket Settings <i class='fa-solid fa-ticket'/></Button>
                        }
                        {permissions.current.canUnclaim &&
                            <Button className='ticket-actions-button' buttonStyle='btn--primary' buttonSize='btn--medium' onClick={unclaim}>Unclaim Ticket <i class='fa-solid fa-ticket'/></Button>
                        }
                        {permissions.current.canClaim &&
                            <Button className='ticket-actions-button' buttonStyle='btn--confirm' buttonSize='btn--medium' onClick={claim}>Claim Ticket <i class='fa-solid fa-ticket'/></Button>
                        }
                        {permissions.current.canOpen &&
                            <Button className='ticket-actions-button' buttonStyle='btn--confirm' buttonSize='btn--medium' onClick={open}>Open Ticket <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                        }
                        {permissions.current.canClose &&
                            <Button className='ticket-actions-button' buttonStyle='btn--cancel' buttonSize='btn--medium' onClick={close}>Close Ticket <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                        }
                        {permissions.current.canDelete &&
                            <Button className='ticket-actions-button' buttonStyle='btn--cancel' buttonSize='btn--medium' onClick={deleteArchive}>Delete Archive <i class='fa-solid fa-ticket'/></Button>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default TicketActions