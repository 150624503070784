export const baseUrl = 'https://supportapi.romestaff.com/';
export const staffGroupID = 5363208;
export const DiscordOAuthUrl = 'https://discord.com/api/oauth2/authorize?client_id=821198674432098334&redirect_uri=https%3A%2F%2Fsupportapi.romestaff.com%2Fapi%2Faccount%2Fverify&response_type=code&scope=identify';
export const url_regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const isElementLoaded = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector);
};

export const dropdown_styles = {
  option: (provided) => ({
      ...provided,
      display: 'flex',
      color: '#000'
  }),
  input: (provided) => ({
      ...provided,
      color: '#000'
  }),
  control: (provided) => ({
      ...provided,
      marginTop: '10px'
  })
}