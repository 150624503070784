import React from 'react';
import '../css/Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--confirm', 'btn--cancel', 'btn--dark', 'btn--info', 'btn--danger'];
const SIZES = ['btn--medium', 'btn--large'];

const Button = ({children, type, path, onClick, buttonStyle, buttonSize, className}) => {
    const setStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const setSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    if (path) {
        return (
            <Link to={path} className='btn-mobile'>
                <button className={`btn ${setStyle} ${setSize} ${className}`} onClick={onClick} type={type}>
                    {children}
                </button>
            </Link>
        )
    } else {
        return (
            <button className={`btn ${setStyle} ${setSize} ${className}`} onClick={onClick} type={type}>
                {children}
            </button>
        )
    };
};

export default Button;