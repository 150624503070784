import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../css/Tickets.css';

import Tickets from '../js/modules/Tickets';
import TicketUserList from '../js/TicketUserList';
import TicketActions from '../js/TicketActions';
import Chat from '../js/Chat';
import { useParams } from 'react-router-dom';
import ChatNotice from '../js/ChatNotice';

function TicketsPage() {
    const { id } = useParams();

    const queryParams = new URLSearchParams(window.location.search);
    const isStaffView = queryParams.get('staff');
    const isArchiveView = queryParams.get('archive');

    return (
        <>
            <div className='tickets-container-image'/>

            {!id && <TicketUserList/>}
            {id && 
                <>
                    <div className='chat-outer-container'>
                        <Chat backToPath={isArchiveView ? '/staff/archivedtickets' : isStaffView ? '/staff/tickets' : '/tickets'} chatid={id}/>
                    </div>
                </>
            }
        </>
    )
};

export default TicketsPage;