import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import '../css/OpenTicket.css';
import '../../App.css';
import '../css/Tickets.css';

import TicketTopicSelect from '../js/TicketTopicSelect';
import { getPanel, openTicket, redirectToTicket } from '../js/modules/Tickets';
import Button from '../js/Button';
import Notice from '../js/Notice';

function OpenTicket() {
    const { id, topicText } = useParams();

    const [state, setState] = useState('loading');
    const [responses, setResponses] = useState([]);

    const panel = useRef();
    const panelTopic = useRef();

    useEffect(() => {
        if (id && !panel.current) {
            getPanel(id)
                .then(async (response) => {
                    if (response.message != 'Success') return setState('not found');

                    response.data.topics.map((topic, _) => {
                        if (topic.text == topicText) {
                            panel.current = response.data;
                            panelTopic.current = topic;

                            if (!panelTopic.current.questions[0]) {
                                openTicket(panel.current.panel_id, panelTopic.current.text, [])
                                    .then((response) => {
                                        if (response.message != 'Success') return setState('error');
                                        redirectToTicket(response.data.ticket_id);
                                    })
                                    .catch((err) => {
                                        setState('error');
                                    });
                            } else {
                                setState('success');
                            };
                        }
                    });

                    if (!panelTopic.current) setState('not found');
                })
                .catch((err) => {
                    setState('not found');
                });
        }
    }, []);

    const formSubmit = (e) => {
        e.preventDefault();

        openTicket(panel.current.panel_id, panelTopic.current.text, responses)
            .then((response) => {
                if (response.message != 'Success') return setState('error');
                redirectToTicket(response.data.ticket_id);
            })
            .catch((err) => {
                setState('error');
            });
    };

    return (
        <>
            <div className='openticket-container-image'/>

            {state == 'error' && <Notice title={{logo: <i class="fa-solid fa-ticket"/>, text: "Failed to Open Ticket"}} content='Something went wrong and we were not able to open a ticket for you at this time. Please try again soon and if the issue persists, please contact a System Engineer.' btns={[<Button type='text' path='/' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back Home <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>}
            {!id && <TicketTopicSelect/>}
            {id && topicText && state == 'loading' &&
                <>
                    <div className='loading-container'>
                        <h3>Loading Category</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {id && panel.current && panelTopic.current && state == 'success' &&
                <>
                    <div className='category-container'>
                        <div className='category-header' style={{'--color': panel.current.color}}>
                            <i className='fa-solid fa-ticket fa-2xl' style={{'--color': tinycolor(panel.current.color).darken(30)}}/>
                            <h3 style={{'--color': tinycolor(panel.current.color).darken(30)}}>{panel.current.title} System Ticket</h3>
                        </div>

                        <form className='category-body' onSubmit={formSubmit}>
                            <p className='category-instructions'>Before proceeding, we ask that you answer the following questions to the best of your ability to help the team assist you best in your ticket.</p>
                            {panelTopic.current.questions.map((question, i) => {
                                question = question.substring(question.length - 1) == '?' ? question : question + '?';

                                return (
                                    <>
                                        <h3>{question}</h3>
                                        <textarea placeholder='Please enter your response.' onChange={(e) => {responses[i] = {question, value: e.target.value}; setResponses(responses)}} required/>
                                    </>
                                )
                            })}

                            <div className='category-body-checkboxcontainer'>
                                <input type='checkbox' required/>
                                <p>I confirm that I have answered the following questions to the best of my ability and recollection. By doing so, I confirm that I am creating this ticket in good faith and conscious.</p>
                            </div>

                            <div className='category-body-btns'>
                                <Button type='submit' className='category-body-submit' buttonStyle='btn--confirm' buttonSize='btn--medium'>Open Ticket <i class="fa-solid fa-ticket"/></Button>
                                <Button type='text' path='/openticket' buttonStyle='btn--primary' buttonSize='btn--medium'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                            </div>
                        </form>
                    </div>
                </>
            }

            {id && (state == 'not found' || !topicText) &&
                <>
                    <div className='category-not-found'>
                        <h3><i className='fa-solid fa-circle-info'/> Category Not Found</h3>
                        <p>This ticket category doesn't appear to exist. Maybe you received a faulty link?</p>

                        <div className='ticket-not-found-btns-container'>
                            <Button className='ticket-not-found-submit' path='/openticket' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
};

export default OpenTicket;