import React, { useContext } from 'react';
import '../css/DisabledPage.css';
import AuthContext from '../js/modules/AuthContext';

function DisabledPage () {
    const authContext = useContext(AuthContext);

    return (
        <>
            <div id='background'>
                <div id='items'>
                    <h3 id='title'>Unable to Access Site</h3>
                    <i class="fa-solid fa-circle-exclamation fa-2xl"></i>
                    <h3>Your account has been disabled by system administrators for misconduct.</h3>
                    <h3>This could have been due to inappropriate conduct in chats or other forms of misconduct.</h3>
                    <a href={`mailto:staffheads@romestaff.com?subject=${authContext.user.rbxuser.username} Support Account Disabled`}>Get in contact with us if you have any questions.</a>
                </div>
            </div>
        </>
    );
};

export default DisabledPage;