import React, { useState, useContext, useEffect } from "react";
import '../../css/TicketSettingsPopup.css';
import AuthContext from "../modules/AuthContext";

import Swal from "sweetalert2";
import Button from "../Button";
import { redirectToAccountPage, redirectToArchivedTickets, applyTicketSettings, unclaimTicket } from "../modules/Admin";
import { getPanels } from "../modules/Tickets";

import Select, { components } from 'react-select';
import { dropdown_styles } from '../../../shared';

function TicketSettingsPopup ({onclose, ticket_perms, ticket}) {
    const [topics, setTopics] = useState();
    const [search, setSearch] = useState();
    const [choseTopic, setChoseTopic] = useState();

    useEffect(() => {
        getPanels()
            .then((response) => {
                const _topics = [];

                for (const panel of response.data) {
                    panel.topics.map((topic, _) => {
                        topic.panel_id = panel.panel_id;
                        _topics.push(topic);
                    });
                };

                setTopics(_topics);
            })
            .catch(() => {
                return;
            });
    }, []);

    const applyChanges = () => {
        if (!choseTopic && !search) return onclose();

        applyTicketSettings(choseTopic, search, (response) => {
            if (response.status == 'error') {
                Swal.fire({
                    title: "Failed to Apply Ticket Settings",
                    text: "An issue occurred while trying to switch this ticket's panel and topic.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            } else if (response.status == 'not found') {
                Swal.fire({
                    title: "Failed to Apply Ticket Settings",
                    text: "The account you searched for does not exist.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            } else {
                Swal.fire({
                    title: "Success!",
                    text: "This ticket has been migrated to your selection.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then((result) => {
                    onclose();
                    window.location.reload();
                });
            }
        });
    };

    const forceUnclaim = () => {
        unclaimTicket((response) => {
            if (response.status == 'ok') {
                Swal.fire({
                    title: "Success!",
                    text: "You have set this ticket to be unclaimed.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    title: "Failed to Unclaim Ticket",
                    text: "There was an issue while trying to unclaim this ticket, the ticket may be closed or is not currently claimed.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    return (
        <>
            {!topics &&
                <div className='loading-container'>
                    <h3>Loading Settings</h3>
                    <div className='load-icon'/>
                </div>
            }

            {topics && 
                <div className='switch-panel-container'>
                    {ticket_perms.canSwitchPanel && 
                        <div className='popup-field'>
                            <h3>Panels & Topics</h3>
                            <span>Here are all the panel topics you can switch to.</span>
                        </div>
                    }
                    
                    {ticket_perms.canSwitchPanel && 
                        <Select id='switch-panel-select' components={{Option: (props) => <components.Option {... props}><img src={props.data.image} style={{height: '20px', width: '20px', borderRadius: '50%', marginRight: '10px'}}/>{props.data.label}</components.Option>}} onChange={(state) => setChoseTopic({panel_id: state.value, topic: state.label})} placeholder='Select Topic...' styles={dropdown_styles} options={topics.map((topic, _) => {
                            return {value: topic.panel_id, label: topic.text, image: topic.logo}
                        })}/>
                    }

                    {ticket_perms.canSwitchTicketOwner && 
                        <div className='popup-field'>
                            <h3>Ticket Owner</h3>
                            <span>Here you can change the account linked to this ticket.</span>

                            <div style={{'width': '100%', 'justifyContent': 'start'}} id='search-bar-holder'>
                                <i class="fa-solid fa-circle-info"></i>
                                <input placeholder='Input account by email, username, discord ID, roblox ID, or account ID.' className='user-view-searchbar' value={search} onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                        </div>
                    }

                    <div className='popup-field'>
                        <h3>Miscellaneous Options</h3>
                        <span>Here are some other actions you can take on this ticket, if any.</span>

                        <div id='misc-btns'>
                            {ticket.staff_ids[0] && ticket_perms.canForceUnclaim &&
                                <Button buttonStyle='btn--danger' buttonSize='btn--medium' className='staff-btn' onClick={forceUnclaim}>Force Unclaim <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                            }
                        </div>
                    </div>

                    <div id='switch-panel-btn-container'>
                        <Button buttonStyle='btn--confirm' buttonSize='btn--medium' className='staff-btn' onClick={applyChanges}>Apply Changes <i class="fa-solid fa-floppy-disk"></i></Button>
                        <Button buttonStyle='btn--cancel' buttonSize='btn--medium' className='staff-btn' onClick={() => onclose()}>Cancel <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                    </div>
                </div>
            }
        </>
    );
};

export default TicketSettingsPopup;