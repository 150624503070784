import './App.css';
import AuthProvider from './components/js/AuthProvider';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import RouterComponent from './components/js/Router';

function App() {
  return (
    <>
      <div className='home-img'/>
      <Router><AuthProvider><RouterComponent/></AuthProvider></Router>
    </>
  );
}

export default App;