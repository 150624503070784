import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import '../css/ResetPassword.css';

import Swal from 'sweetalert2';

import { resetPassword } from "../js/modules/User";
import Button from "../js/Button";
import Notice from '../js/Notice';

function ResetPassword() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();

    console.log(token)
    const formSubmit = async (e) => {
        e.preventDefault();
        if (!password || !passwordConfirm) return;
        if (password != passwordConfirm) {
            return Swal.fire({
                title: "Passwords Do Not Match",
                text: "The passwords you entered do not match up. Please try again.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        }

        const response = await resetPassword(token, password);
        if (response.message == 'Success') {
            Swal.fire({
                title: "Success!",
                text: "Password Reset.",
                icon: "success",
                confirmButtonText: "Ok"
            }).then((result) => {
                window.location = "./login";
            });
        } else if (response.message == 'Not Found') {
            Swal.fire({
                title: "Failed to Reset",
                text: "Your reset password request is no longer valid, it likely expired for the security of your account. Try again at your convenience.",
                icon: "error",
                confirmButtonText: "Ok"
            }).then((result) => {
                window.location = "./request-reset-password";
            });
        } else {
            Swal.fire({
                title: "Failed",
                text: "An unexpected error occurred, please try again later.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        }
    };

    return (
        <>
            {!token &&
                <Notice title={{text: 'Invalid Reset Request'}} content="Reset request associated with the token could not be found." btns={[<Button path='/' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>   
            }
            {token &&
                <form className='reset-form' onSubmit={formSubmit}>
                    <h3><i class="fa-solid fa-circle-info"/> Reset Password</h3>

                    <div className='reset-form-elements'>
                        <input type='password' placeholder='Enter a New Password' id='password' value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }}/>
                        <input type='password' placeholder='Confirm New Password' id='confirm-password' value={passwordConfirm} onChange={(e) => {
                            setPasswordConfirm(e.target.value);
                        }}/>
                    </div>

                    <Button className='reset-form-submit' buttonStyle='btn--confirm'>Submit <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                </form>
            }
        </>
    );
}

export default ResetPassword;