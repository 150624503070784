import React, { useState, useEffect, useRef, useContext } from 'react';
import { url_regex } from '../../shared';

import { deleteMessage } from './modules/Messaging';

import Swal from 'sweetalert2';
import AuthContext from './modules/AuthContext';
import ChatNotice from './ChatNotice';
import '../../App.css';
import '../css/Message.css';

function Message ({ user, id, notices, content, onMessageClick, canDelete, time, width, extraStyle, order, children }) {
    const authContext = useContext(AuthContext);
    const [actionState, setActionState] = useState('hidden');

    const account_id = user.account_id;
    const isMe = account_id == authContext.user.id;

    const message_date = new Date(0);
    message_date.setUTCSeconds(time);

    const deleteAction = () => {
        deleteMessage(id, (messageResponse) => {
            if (messageResponse.status != 'ok') {
                Swal.fire({
                    title: "Failed to Delete Message",
                    text: "There was an issue while trying to delete this message, it has either already been deleted or you do not have the proper permissions to delete it.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            };
        });
    };

    return (
        <div onMouseOverCapture={() => setActionState('showing')} onMouseOutCapture={() => setActionState('hidden')} className={`message-container-${isMe ? 'me' : 'you'}`} style={{...extraStyle, '--width': width}}>
            {user.account_id != 'Bot' && 
                <div className='message-header' onClick={(e) => onMessageClick(user)}>
                    {!order &&
                        <>
                            <img src={user.rbxuser.imageurl}/>
                            <span style={{marginRight: '0px'}}>{isMe ? 'You' : user.account_id == "Bot" && 'Roman Systems' || user.rbxuser.username} • {message_date.toLocaleDateString()}</span>
                            {user.permLevel > 1 && <span style={{color: '#f5a442'}}>• Staff</span>}
                        </>
                    }

                    {order == 'reverse' &&
                        <>
                            {user.permLevel > 1 && <span style={{color: '#f5a442'}}>Staff •</span>}
                            <span style={{marginLeft: '0px'}}>{message_date.toLocaleDateString()} • {isMe ? 'You' : user.rbxuser.username}</span>  
                            <img src={user.rbxuser.imageurl}/>
                        </>
                    }
                </div>
            }

            {user.account_id == 'Bot' ?
                notices.map((notice, _) => {
                    return <ChatNotice color={notice.color} sections={notice.sections}/>
                })
            :   <div className='message-body'>
                    <p>{content.split(" ").map((part, _) => {
                        return(url_regex.test(part) ? <a className='message-link' target='_blank' href={part}>{part} </a> : part + " ")
                    })}</p>
                    {children}
                </div>
            }

            {actionState == 'showing' && (canDelete) &&
                <div className='message-action-row'>
                    {canDelete &&
                        <i onClick={(e) => {
                            Swal.fire({
                                title: "Delete Message",
                                text: "Are you sure you'd like to delete this message? This action cannot be undone.",
                                icon: "question",
                                showDenyButton: true,
                                confirmButtonText: "Delete",
                                denyButtonText: "Cancel",
                            }).then((action) => {
                                if (action.isConfirmed) deleteAction();
                            });
                        }} class="fa-solid fa-trash delete-btn"/>
                    }
                </div>
            }
        </div>
    )
};

export default Message;