import Notice from '../js/Notice';
import Button from '../js/Button';

function UnauthorizedPage() {
    return (
        <>
            <Notice title={{text: 'Unable to View Page'}} content='You are not permitted to view this page or its content. If you believe this is a mistake, please contact an administrator.' btns={[<Button path='/' buttonSize='btn--medium' buttonStyle='btn--cancel'>Back Home <i class="fa-solid fa-arrow-right-to-bracket"/></Button>]}/>
        </>
    );
};

export default UnauthorizedPage;