import React, { useState, useContext, useEffect, useRef } from "react";
import AuthContext from '../js/modules/AuthContext';

import Swal from "sweetalert2";

import { getDiscordUser, deleteUser, getPermissionSets, redirectToArchivedTickets } from '../js/modules/Admin';
import Popup from "./Popup";
import PermissionLevelPopup, { getFields } from "./popups/PermissionLevelPopup";
import AccountModeratePopup, { getModerateFields } from './popups/AccountModeratePopup';
import Button from '../js/Button';

import '../../App.css';
import '../css/AccountViewer.css';

function AccountViewer ({account}) {
    const query = new URLSearchParams(window.location.search);
    const redirect = query.get('redirect');

    const discordUser = useRef();
    const permissionSets = useRef();

    const [discordLoadState, setDiscordLoadState] = useState('loading');
    const [permLoadState, setPermLoadState] = useState('loading');

    const [popup, setPopup] = useState();
    const authContext = useContext(AuthContext);

    const accountDate = new Date(0);
    accountDate.setUTCSeconds(account.accountCreated);

    useEffect(() => {
        getDiscordUser(account.discordid)
            .then((response) => {
                if (response.message != 'Success' && !discordUser.current) return setDiscordLoadState('not found'); discordUser.current = null;
            
                setDiscordLoadState('success');
                discordUser.current = response.data;
            })
            .catch(() => {
                setDiscordLoadState('error');
                discordUser.current = null;
            })

        getPermissionSets()
            .then((response) => {
                setPermLoadState('success');
                permissionSets.current = response.data;
            });
    }, []);

    return (
        <>
            {popup &&
                <Popup onclose={() => setPopup()} title={popup.title} contentchildren={popup.contentchildren} headerchildren={popup.headerchildren} description={popup.description} fields={popup.fields}/>
            }

            <div className='account-viewer-body'>
                <div className='account-viewer-header' onClick={() => window.location = redirect || '/admin'}>
                    <i class="fa-solid fa-arrow-left-long"/>
                    <h3>{account.rbxuser.username}</h3>
                    <span>{account.id}</span>
                </div>
                
                <div className='account-viewer-container'>
                    <div id='info-container'>
                        <div className='info-header'><span>Account Information</span></div>
                        
                        <img src={account.rbxuser.imageurl}/>
                        <h3>{account.rbxuser.username}</h3>
                        <div id='info-container-elements'>
                            <div className='info-container-element'><span>Email: {account.email}</span></div>
                            <div className='info-container-element'><span>Account ID: <span style={{'color': '#f5a442'}}>{account.id}</span></span></div>
                            <div className='info-container-element'><span>Account Status: <span style={{'color': '#f5a442'}}>{account.status}</span></span></div>
                            <div className='info-container-element'><span>Account Created On: {accountDate.toLocaleDateString()}</span></div>
                            <div className='info-container-element'><span>Roblox ID: {account.rbxid}</span></div>
                            {account.discordid != '0' && <div className='info-container-element'><span>Discord ID: {account.discordid}</span></div>}
                            {account.permissionFlags.IS_STAFF && <div className='info-container-element'><span>Tickets Claimed: {account.ticketsClaimed}</span></div>}
                            <div className='info-container-element'><span>Permission: {account.permissionName} (Level {account.permissionLevel})</span></div>
                            <div className='info-container-element'>
                                {account.verification == 'false' ? <span style={{color: '#c93434'}}>Account is Not Verified</span> : <span style={{color: '#34c939'}}>Account is Verified</span>}    
                            </div>
                        </div>
                    </div>

                    {account.verification == 'true' &&
                        <>
                            <div id='discord-user-container'>
                                <div className='info-header' style={{'--image': `url(${discordUser.current && discordUser.current.banner.url})`}}><span>Discord Account Information</span></div>
                                {discordLoadState == 'loading' &&
                                    <div style={{'paddingBottom': '50px'}}>
                                        <div className='load-icon'/>
                                    </div>
                                }
                                {(discordLoadState == 'error' || discordLoadState == 'not found') &&
                                    <>
                                        <h3 style={{'textAlign': 'center'}}>Unable to Load Discord Account Information</h3>
                                        <span style={{'textAlign': 'center', 'color': '#c93434'}}>Discord user may no longer exist, or the API is unavailable at this time.</span>
                                        <i style={{'color': '#c93434', 'marginTop': '20px', 'paddingBottom': '20px'}} class="fa-solid fa-circle-exclamation fa-2xl"></i>
                                    </>
                                }
                                {discordUser.current && discordLoadState == 'success' &&
                                    <>
                                        <img style={{'maxWidth': '150px'}} id='discord-profile' src={discordUser.current.avatar.url == '#' ? '/images/not-found.png' : discordUser.current.avatar.url}/>
                                        <h3>{discordUser.current.username}{discordUser.current.discriminator != '0' ? `#${discordUser.current.discriminator}` : ''}</h3>

                                        <div id='discord-info-container-elements'>
                                            <div className='info-container-element'><span>Discord ID: {discordUser.current.id}</span></div>
                                            <div className='info-container-element'><span>Account Created On: {new Date(discordUser.current.created_at).toLocaleDateString()}</span></div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }

                    <div id='actions-container'>
                        <div className='info-header'><span>Account Actions</span></div>
                        <div id='actions-container-elements'>
                            {<Button className='staff-btn' buttonStyle='btn--primary' buttonSize='btn--medium' onClick={(e) => {
                                redirectToArchivedTickets(account.rbxuser.username);
                            }}>View Archived Tickets <i class="fa-solid fa-arrow-right-to-bracket"/></Button>}

                            {permLoadState == 'success' && permissionSets.current && authContext.user.permissionFlags.CAN_EDIT_ACCOUNTS && authContext.user.permissionLevel > account.permissionLevel && <Button className='staff-btn' buttonStyle='btn--primary' buttonSize='btn--medium' onClick={(e) => {
                                setPopup({title: 'Permission Level', headerchildren: <span id='blue-notice'>ADMIN</span>, fields: getFields(account, authContext.user.permissionLevel, permissionSets.current), contentchildren: <PermissionLevelPopup meLevel={authContext.user.permissionLevel} permissionSets={permissionSets.current} onclose={() => setPopup()} account={account}/>})
                            }}>Permission Level <i class="fa-solid fa-file-invoice"></i></Button>}
                            
                            {authContext.user.permissionFlags.CAN_PUNISH_ACCOUNTS && authContext.user.permissionLevel > account.permissionLevel && <Button className='staff-btn' buttonStyle='btn--cancel' buttonSize='btn--medium' onClick={(e) => {
                                setPopup({title: 'Moderate Account', headerchildren: <span id='blue-notice'>ADMIN</span>, fields: getModerateFields(account), contentchildren: <AccountModeratePopup onclose={() => setPopup()} account={account}/>})
                            }}>Moderate Account <i class="fa-solid fa-file-invoice"></i></Button>}

                            {authContext.user.permissionFlags.CAN_DELETE_ACCOUNTS && authContext.user.permissionLevel > account.permissionLevel && <Button className='staff-btn' buttonStyle='btn--cancel' buttonSize='btn--medium' onClick={(e) => {
                                deleteUser(account.id)
                                    .then((response) => {
                                        if (response.message != 'Success') {
                                            return Swal.fire({
                                                title: "Failed to Delete Account",
                                                text: "You are not permitted to delete this account. This could be due to permission level similarities or the account no longer exists.",
                                                icon: "error",
                                                confirmButtonText: "Ok"
                                            });
                                        };

                                        Swal.fire({
                                            title: "Success!",
                                            text: "This account has been deleted.",
                                            icon: "success",
                                            confirmButtonText: "Ok"
                                        }).then((result) => {
                                            window.location = "/admin"
                                        });
                                    })
                                    .catch(() => {
                                        Swal.fire({
                                            title: "Failed to Delete Account",
                                            text: "An error occurred.",
                                            icon: "error",
                                            confirmButtonText: "Ok"
                                        });
                                    });
                            }}>Delete Account <i class="fa-solid fa-trash"></i></Button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountViewer;