import React from 'react';
import '../css/APIUnavailable.css';

function APIUnavailable () {
    return (
        <>
            <div id='background-api-unavailable'>
                <div id='items-api-unavailable'>
                    <h3 id='title-api-unavailable'>Site Maintenance</h3>
                    <i class="fa-solid fa-circle-exclamation fa-2xl"></i>
                    <h3>The support site services are currently unavailable due to maintenance.</h3>
                    <h3>Please check back soon to have your issues resolved.</h3>
                    <span style={{'color': '#f5a442', 'marginTop': '10px'}}>If this issue is persisting over 24 hours, let us know in our <a href='https://discord.gg/ydamod'>discord server.</a></span>
                </div>
            </div>
        </>
    );
};

export default APIUnavailable;