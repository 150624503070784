import React, { useState, useEffect } from "react";

import '../../App.css';
import '../css/Attachment.css';

function readImage(file, callback) {
    if (file.type && !file.type.startsWith('image/')) {
        return;
    }
        
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
        callback(event.target.result);
    });
    reader.readAsDataURL(file);
};

function Attachment({upload, file, deletedCallback}) {
    const [fileResolvable, setFileResolvable] = useState();
    useEffect(() => {
        (async() => {
            if (upload) {
                readImage(file, (resolvable) => {
                    setFileResolvable(resolvable);
                });
            };
        })();
    }, [file]);

    return (
        <>
            {upload && fileResolvable &&
                <>
                    <div className='attachment-upload-container'>
                        <div className='attachment-upload-actions'>
                            <div className='attachment-upload-actions-delete' onClick={() => deletedCallback()}>
                                <i class="fa-solid fa-trash"/>
                            </div>
                        </div>

                        <div className='attachment-upload-content'>
                            <img src={fileResolvable}/>                            
                            <span>{file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}</span>
                        </div>
                    </div>
                </>
            }

            {upload == false &&
                <>  
                    <div className='attachment-container'>
                            <a href={file} target='_blank'>
                                <img src={file}/>
                            </a>
                    </div>
                </>
            }
        </>
    )
};

export default Attachment;