export const CodeOfConduct = {
    fields: [{title: 'Game Conduct', content: 'All members of the Staff & Moderation Team must abide by the following rules when playing any game related to the Roman Empire:\n\n1. All Staff & Moderation Team members must abide by the in-game rules.\n2. Staff & Moderation Team members are expected to act mature and professional whenever in-game, meaning they must follow the roleplay rules set for them.\n3. Abuse of admin in-game shall be prohibited under all circumstances. In-game abuse of admin shall be defined as:\n(a) Usage of moderation commands for non-moderation purposes, such as teleportation to arrest someone or,\n(b) Unnecessarily harsh or unnecessarily lenient punishments or,\n(c) Usage of moderation commands to punish people who have not broken any rules or,\n(d) Exploiting flaws in the admin, such as the use of commands that one should not have access to or,\n(e) Granting admin to those who are not entitled to it.\n4. All Staff & Moderation Team members are responsible and accountable for their actions. They are also responsible for not reporting or acting against misconduct or breaking the rules within our games.\n5. Staff & Moderation Team members must ensure fairness and equity in treatment; all appellants and accused rule-breakers must be treated fairly and given full due process as outlined herein.\n6. Your username must always be contained within the reason when banning perpetrators. This ensures we can track all events and enforcement should an appeal occur.'},
        {title: 'Discord Conduct', content: 'All members of the Staff & Moderation Team must abide by the following rules when on any Discord server related to the Roman Empire:\n\n1. All Staff & Moderation Team members must abide by the Discord server rules.\n2. Maturity and professionalism are expected from all Staff & Moderation Team members.\n3. Admin abuse in Discord servers shall be prohibited under all circumstances. Discord abuse of admin shall be defined as:\n(a) Usage of moderation commands to punish people who have not broken any rules or,\n(b) Unnecessarily harsh or unnecessarily lenient punishments or,\n(c) Editing role permissions without consulting and gaining authorization from the Deputy Head of Staff+ or,\n(d) Editing channel permissions without consulting and gaining authorization from the Deputy Head of Staff+ or,\n(e) Deleting channels without consulting and gaining authorization from the Deputy Head of Staff+ or,\n(f) Adding channels without consulting and gaining authorization from the Deputy Head of Staff+ or,\n(g) Moving people around channels without due reason or,\n(h) Muting people without due reason or,\n(i) Deafening people without reason or,\n(j) Giving oneself roles they are not entitled to or,\n(k) Giving others roles they are not entitled to or,\n(l) Removing roles from others without due reason.\n4. All Staff & Moderation Team members are responsible and accountable for their actions. They are also responsible for not reporting, acting against misconduct, or breaking the rules within our Discord servers.\n5. Staff & Moderation Team members must ensure fairness and equity in treatment; all appellants and accused rule-breakers must be treated fairly and given full due process as outlined herein.\n6. No staff member shall take on a ticket they are not entitled to.\n7. No ticket submitted shall be automatically closed. Doing so would be considered a severe breach of conduct and a fair moderation process.'},
        {title: 'Disclosing Information', content: 'Staff members are not permitted to share any information within the Roman Moderation Discord server or The Roman Empire staff channels. This includes the staff website, support tickets, chat channels, announcements, moderation guidelines, or other resources staff members have.'}
    ]
}

export const TicketAppeals = {
    description: 'The following directive should take in-effect Tuesday, May 2nd, 2023. This directive should be considered an administrative order, and any incompliance by any staff member will result in immediate termination.',
    fields: [{title: '101.1 Purpose & Scope', content: 'This directive is designed to inform staff members on the proper procedure when dealing with ticket appeals within the Staff & Moderation Discord Server. Staff members should follow all procedures listed in this directive.'}, {title: '101.2 Directive Highlights', content: 'Once a staff member issues a permanent ban, the user will open a game ban appeal ticket within the discord server. This directive will outline how you should deal with permanent in-game bans.'}, {title: '101.3 Ticket Handling', content: 'Once the user opens a ticket after being issued a permanent ban, they typically ask for the ban to be lowered or removed. This directive will allow you as a staff member to change the users banned time to a specific time frame.\n\nExample: A user is banned for ERP, and this is their first warning/moderation history. Instead of telling the ticket user to come back in 30 days to open another ticket, you can change the ban frame from permanent to 30 days. This will allow the system to remove the ban in 30 days without staff needing to review the case. Staff leadership is trusting your judgment as a staff member.'},
        {title: '101.4 Adjusting Bans', content: 'Staff members that wish to update a users ban from permanent to a specific time frame must have access to the staff hub on romestaff.com. On the staff hub page, you will see a section called “TYPE ROBLOX ID TO EDIT BAN DAY AMOUNT.” Once you type the ticket users Roblox ID and click enter if the user is banned, a pop-up window will appear with the ban information. You will then see a dropdown at the bottom that allows you to change the ban from permanent to a set amount of days. Once you select the day and click request change, it will be sent to system admins to review and update. Your request may get rejected.'},
        {title: '101.5 Appeal Terms', content: 'When a staff member denies the ticket users appeal, they cannot open another appeal ticket for 24 hours unless approved by an Administrator+. Should the ticket user violate this rule, they may be muted or kicked from the Moderation Discord Server.'},
        {title: 'Any questions regarding this directive should be directed to a Deputy Head of Staff+'}
    ]
}

export const TicketHandling = {
    description: 'The following things should happen when a staff member is handling a ticket within the Roman Moderation Discord.\n\n• Professionalism\n• Courtesy\n• Respect\n\nThe following things should NOT happen when a staff member is handling a ticket within the Roman Moderation Discord.\n\n• The use of any emoji that is not staff related.\n• Using inappropriate language.\n• Conversations that are not related to the nature of the ticket.\n\nWhen staff members are handling tickets, they may come across a moderation issued by their superior. The following procedure should be followed.\n\n• Trial Moderator > Handed to Moderators+\n• Moderators > Can handle all moderations a trial moderator or moderator issued.\n• Senior Moderators > Handed to the Senior Moderator or Administrator+\n• Administrator > Handed to the Administrator or Senior Administrator+\n• Senior Administrator > Handed to the Senior Administrator or Deputy Head of Staff+\n• Deputy Head of Staff > Handed to the Deputy Head of Staff or Head of Staff\n• Head of Staff > Handed to the Head of Staff\n\nStaff members should not handle an Administrator+ moderation appeal. Moderators may handle all other moderators or trial moderators tickets.'
}