import React, { useState, useContext } from "react";
import '../../css/PermissionLevelPopup.css';
import AuthContext from "../modules/AuthContext";

import Swal from "sweetalert2";
import Button from "../Button";
import Select, { components } from 'react-select';
import { dropdown_styles } from "../../../shared";
import { changePermLevel } from "../modules/Admin";

export const getFields = (account) => {
    return ([
        {title: 'Current Level', content: `This accounts current permission is ${account.permissionName} which grants them a permission level of ${account.permissionLevel}.`},
        {title: 'Account Privileges', content: 'Included are the system flags which this account does or does not qualify for under their current status.', children: 
            <>
                <div className='popup-flags-container'>
                    {Object.keys(account.permissionFlags).map((flag, _) => {
                        return (
                            <div className='popup-flag-content-container'>
                                <span>{flag}: <span style={{'color': account.permissionFlags[flag] == true ? '#34c939' : '#c93434'}}>{account.permissionFlags[flag] == true ? 'True' : 'False'}</span></span>
                            </div>
                        );
                    })}
                </div>
            </>
        },
        {title: 'Change Level', content: 'Use the drop down below to select a new permission level for the user. Be cognisent as changes here could permit abuse.'}
    ]);
};

function PermissionLevelPopup ({account, permissionSets, onclose}) {
    const [permDropdown, setPermDropdown] = useState();
    const authContext = useContext(AuthContext);

    const applyChanges = () => {
        if (!permDropdown) return onclose();

        changePermLevel(account.id, Number(permDropdown.value))
            .then((response) => {
                if (response.message != 'Success') {
                    return Swal.fire({
                        title: "Failed to Change Permission Level",
                        text: "You are not permitted to change this accounts permission level, or you are not permitted to set it to this level due to your level restrictions.",
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                };

                Swal.fire({
                    title: "Success!",
                    text: "This account permission level has been edited.",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then((result) => {
                    onclose();
                    window.location.reload();
                });
            })
            .catch(() => {
                return Swal.fire({
                    title: "Failed to Change Permission Level",
                    text: "You are not permitted to change this accounts permission level, or you are not permitted to set it to this level due to your level restrictions.",
                    icon: "error",
                    confirmButtonText: "Ok"
                });
            });
    };

    return (
        <>
            <Select id='popup-change-perm-select' onChange={(e) => setPermDropdown(e)} defaultValue={{value: account.permissionLevel, label: permissionSets[account.permissionLevel].Name}} placeholder='Select a Permission Level...' styles={dropdown_styles} options={Object.keys(permissionSets).map((level, _) => {
                return {value: level, label: permissionSets[level].Name}
            })}/>

            <div id='popup-change-perm-btn-container'>
                <Button buttonStyle='btn--confirm' buttonSize='btn--medium' className='staff-btn' onClick={applyChanges}>Apply Changes <i class="fa-solid fa-floppy-disk"></i></Button>

                <Button buttonStyle='btn--cancel' buttonSize='btn--medium' className='staff-btn' onClick={() => onclose()}>Cancel <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
            </div>
        </>
    );
};

export default PermissionLevelPopup