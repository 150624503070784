import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import '../css/OpenTicket.css';

import AuthContext from '../js/modules/AuthContext'
import Button from '../js/Button';
import PanelItem from '../js/PanelItem';
import { getPanels } from '../js/modules/Tickets';

function TicketTopicSelect() {
    const authContext = useContext(AuthContext);
    const [state, setState] = useState('loading');
    const [panels, setPanels] = useState(false);

    useEffect(() => {
        getPanels()
            .then((response) => {
                setPanels(response.data);
                setState('success');
            })
            .catch((err) => {
                setState('error');
            });
    }, []);

    return (
        <>
            {state == 'loading' && 
                <>
                    <div className='loading-container'>
                        <h3>Loading Categories</h3>
                        <div className='load-icon'/>
                    </div>
                </>
            }

            {state == 'success' && panels != false &&
                <>
                    <div className='container-header'>
                        <h3><i className='fa-solid fa-circle-info'/> Ticket Categories</h3>
                        <p>Please select one of the categories in the ticket panels listed below to open your ticket.</p>
                    </div>
                    <div className='container'>
                        <ul className='panels__items'>
                            {panels.map((panel, index) => {
                                if (panel.public_visible == 'true' || (panel.public_visible == 'false' && authContext.user.permissionLevel >= panel.staff_permLevel)) {
                                    return <PanelItem
                                        publicvisible={panel.public_visible}
                                        panelid={panel.panel_id}
                                        title={panel.title}
                                        color={panel.color}
                                        description={panel.description}
                                        topics={panel.topics}
                                    />
                                } else {
                                    return (<></>)
                                }
                            })}  
                        </ul>
                    </div>
                </>
            }
        </>
    )    
};

export default TicketTopicSelect;