import '../../App.css';
import '../css/SignUp.css';

import Swal from 'sweetalert2';
import Button from '../js/Button';

import { Link } from 'react-router-dom';
import Verify from '../js/Verify';

import { baseUrl } from '../../shared';
import React, { useState } from 'react';

function SignUp() {
    const [email, setEmail] = useState();

    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();

    const [username, setUsername] = useState();

    const [verifyVisible, setVerifyVisible] = useState(false);
    const [signupVisible, setSignupVisible] = useState(true);

    const signup = async (e) => {
        e.preventDefault();

        if (passwordConfirm != password) {
            return Swal.fire({
                title: "Passwords Do Not Match",
                text: "The passwords you entered do not match up. Please try again.",
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
        
        const response = await fetch(baseUrl + 'api/account/sign-up', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
            email: email,
            username: username,
            password: password
        })});
        const responseInfo = await response.json();

        if (responseInfo.message == 'Success') {
            localStorage.setItem('success-email', email);
            localStorage.setItem('success-pswd', password);
            
            setSignupVisible(false);
            setVerifyVisible(true);
        } else if (responseInfo.message == 'Account Email Exists') {
            Swal.fire({
                title: "Account Found",
                text: "Did you mean to login?",
                icon: "question",
                showDenyButton: true,
                confirmButtonText: "Login",
                denyButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location = "./login";
                };
            });
        } else if (responseInfo.message == 'Error') {
            Swal.fire({
                title: "Failed to Sign Up",
                text: "An issue occurred on our end, please try again later.",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'Account Username Exists') {
            Swal.fire({
                title: "Failed to Sign Up",
                text: "An existing account is already linked to this ROBLOX username: " + username + ".",
                icon: "error",
                confirmButtonText: "Ok"
            });
        } else if (responseInfo.message == 'No Roblox Account') {
            Swal.fire({
                title: "Failed to Sign Up",
                text: "A ROBLOX Account with the username you supplied does not exist.",
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
    };

    return (
        <>
            <div className='signup-container-image'/>

            {signupVisible &&
                <>
                    <form className='signup-form' onSubmit={signup}>
                        <h3><i class="fa-solid fa-circle-info"/> Welcome!</h3>

                        <label for='email'><i class='fa-solid fa-at fa-xs'/> Email</label>
                        <input type='email' placeholder='Enter a Valid Email' id='email' value={email} onChange={(e) => {
                            setEmail(e.target.value);
                        }}/>

                        <label for='username'><i class="fa-solid fa-user fa-xs"/> ROBLOX Username</label>
                        <input type='text' placeholder='Enter a Valid ROBLOX Username' id='username' value={username} onChange={(e) => {
                            setUsername(e.target.value);
                        }}/>

                        <label for='password'><i class="fa-solid fa-lock fa-xs"/> Password</label>
                        <input type='password' placeholder='Enter a Valid Password' id='password' value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }}/>

                        <label for='password-confirm'><i class="fa-solid fa-lock fa-xs"/> Confirm Password</label>
                        <input type='password' placeholder='Enter a Valid Password' id='password-confirm' value={passwordConfirm} onChange={(e) => {
                            setPasswordConfirm(e.target.value);
                        }}/>

                        <Button className='signup-form-submit' buttonStyle='btn--primary'>Sign Up <i class="fa-solid fa-arrow-right-to-bracket"/></Button>
                        <Link to='/login' className='login-hint'>
                            Already have an account? Login.
                        </Link>
                    </form>
                </>
            }

            {verifyVisible != false && <Verify/>}
        </>
    )
};

export default SignUp;