import React, { useState, useContext } from "react";
import '../../css/AccountModeratePopup.css';
import AuthContext from "../modules/AuthContext";

import Swal from "sweetalert2";
import Button from "../Button";
import { redirectToAccountPage, changeStatus, syncInformation } from "../modules/Admin";

export const getModerateFields = (account) => {
    return ([
        {title: 'Actions', content: 'Here are all the actions you can take.'}
    ]);
};

function AccountModeratePopup ({account, onclose}) {
    return (
        <>
            <div className='account-moderate-btns-container'>
                {account.status == 'Active' && <Button buttonStyle='btn--cancel' buttonSize='btn--medium' className='staff-btn' onClick={() => {
                    changeStatus(account.id, 'Disabled')
                        .then((response) => {
                            if (response.message != 'Success') {
                                Swal.fire({
                                    title: "Failed to Moderate Account",
                                    text: "There was an issue while trying to moderate this account.",
                                    icon: "error",
                                    confirmButtonText: "Ok"
                                });
                            } else {
                                Swal.fire({
                                    title: "Success!",
                                    text: "You have disabled this account.",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                }).then(() => {
                                    window.location.reload();
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                title: "Failed to Moderate Account",
                                text: "There was an issue while trying to moderate this account.",
                                icon: "error",
                                confirmButtonText: "Ok"
                            });
                        });
                }}>Disable Account <i class="fa-solid fa-user"/></Button>}

                <Button buttonSize='btn--medium' className='staff-btn' onClick={() => {
                    syncInformation(account.id)
                        .then((response) => {
                            if (response.message != 'Success') {
                                Swal.fire({
                                    title: "Failed to Sync Account Information",
                                    text: "There was an issue while trying to moderate this account.",
                                    icon: "error",
                                    confirmButtonText: "Ok"
                                });
                            } else {
                                Swal.fire({
                                    title: "Success!",
                                    text: "You have resynced this accounts information!",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                }).then(() => {
                                    window.location.reload();
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                title: "Failed to Sync Account Information",
                                text: "There was an issue while trying to moderate this account.",
                                icon: "error",
                                confirmButtonText: "Ok"
                            });
                        });
                }}>Resync Account Info <i class="fa-solid fa-rotate"/></Button>

                {account.status == 'Disabled' && <Button buttonStyle='btn--primary' buttonSize='btn--medium' className='staff-btn' onClick={() => {
                    changeStatus(account.id, 'Active')
                        .then((response) => {
                            if (response.message != 'Success') {
                                Swal.fire({
                                    title: "Failed to Moderate Account",
                                    text: "There was an issue while trying to moderate this account.",
                                    icon: "error",
                                    confirmButtonText: "Ok"
                                });
                            } else {
                                Swal.fire({
                                    title: "Success!",
                                    text: "You have enabled this account.",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                }).then(() => {
                                    window.location.reload();
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                title: "Failed to Moderate Account",
                                text: "There was an issue while trying to moderate this account.",
                                icon: "error",
                                confirmButtonText: "Ok"
                            });
                        });
                }}>Enable Account <i class="fa-solid fa-user"/></Button>}
            </div>
        </>
    );
};

export default AccountModeratePopup;